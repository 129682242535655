import {
  TimePicker,
  TimePickerChangeEvent
} from '@progress/kendo-react-dateinputs';
import {
  Input,
  InputChangeEvent,
  NumericTextBoxChangeEvent,
  TextArea,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { DateTime } from 'luxon';
import {
  AnswerType,
  CheckboxOption
} from '../../types/game-document/entities/task-content';
import { getHourMinuteFormat } from '../../utils/timer';
import {
  AnswerCheckbox,
  AnswerFile,
  AnswerRadio,
  AnswerStatus
} from '../questionnaire';
import AnswerImage from './answer-image';
import AnswerVideo from './answer-video';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import React, { JSX, useContext } from 'react';
import { generateTitleById } from '../../utils/game-document/display-languages';

export interface AnswerProps {
  isReadonly?: boolean;
  name?: string;
  answerType?: AnswerType;
  answerInput?: string | number;
  checkboxOption?: CheckboxOption[];
  options?: string[];
  answerOptionIndex?: number;
  isCorrectAnswer?: boolean;
  taskId?: string;
  formId?: string;
  isDisabled?: boolean;
  isManualScoring?: boolean;
  isFileUploaded?: boolean;
  onChangeOptionIndex?: (index: number) => void;
  onCheckboxChange?: (index: number, value: boolean) => void;
  onChangeAnswerInput?: (answer: string | number | File, size?: number) => void;
}

const Answer = ({
  isReadonly = false,
  name,
  answerType,
  answerInput,
  checkboxOption,
  options,
  answerOptionIndex,
  isCorrectAnswer,
  taskId = '',
  formId = '',
  isDisabled = false,
  isManualScoring = false,
  isFileUploaded = false,
  onChangeAnswerInput = () => {},
  onCheckboxChange = () => {},
  onChangeOptionIndex = () => {}
}: AnswerProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const onChangeOptionIndexHandler = (index: number) => {
    onChangeOptionIndex(index);
  };

  const onTextInputChangeHandler = (e: InputChangeEvent) => {
    onChangeAnswerInput(e.value, 0);
  };

  const onNumericInputChangeHandler = (e: NumericTextBoxChangeEvent) => {
    const value: number = e.target.value ?? 0;
    onChangeAnswerInput(value, 0);
  };

  const onTextAreaInputChangeHandler = (e: TextAreaChangeEvent) => {
    onChangeAnswerInput(e.value, 0);
  };

  const onSelectedImageChangeHandler = (
    blobUrl: string | File,
    size?: number
  ) => {
    onChangeAnswerInput(blobUrl, size);
  };

  const onCheckboxChangeHandler = (index: number, value: boolean) => {
    onCheckboxChange(index, value);
  };

  const onTimePickerChangeHandler = (e: TimePickerChangeEvent) => {
    const timeFormat: string = getHourMinuteFormat(e.value);
    onChangeAnswerInput(timeFormat, 0);
  };

  const renderAnswer = (): JSX.Element => {
    switch (answerType) {
      case 'file':
        return (
          <AnswerFile
            isReadonly={isReadonly}
            selectedImage={answerInput?.toString()}
            onSelectedImageCallback={onSelectedImageChangeHandler}
            isDisabled={isDisabled}
          />
        );

      case 'video':
        return (
          <AnswerVideo
            isReadonly={isReadonly}
            selectedImage={answerInput?.toString()}
            onSelectedImageCallback={onSelectedImageChangeHandler}
            isDisabled={isDisabled}
            isFileUploaded={isFileUploaded}
            formId={formId}
          />
        );
      case 'image':
        return (
          <AnswerImage
            isReadonly={isReadonly}
            selectedImage={answerInput?.toString()}
            onSelectedImageCallback={onSelectedImageChangeHandler}
            isDisabled={isDisabled}
            formId={formId}
          />
        );
      case 'radio':
        return (
          <AnswerRadio
            group={name}
            options={options}
            answerOptionIndex={answerOptionIndex}
            onChangeOptionIndex={onChangeOptionIndexHandler}
            isCorrectAnswer={isCorrectAnswer}
            taskId={taskId}
            formId={formId}
          />
        );
      case 'checkbox':
        return (
          <AnswerCheckbox
            group={name}
            checkboxOptions={checkboxOption}
            onChange={onCheckboxChangeHandler}
            taskId={taskId}
            formId={formId}
            isDisabled={isDisabled}
            isReadonly={isReadonly}
          />
        );
      case 'text':
        return (
          <>
            <Input
              id={'answer-text'}
              value={answerInput}
              onChange={onTextInputChangeHandler}
              placeholder={
                generateTitleById(
                  '9218c923-0be1-47f8-ae56-565694b5de33',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Type your answer here'
              }
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus
              isCorrectAnswer={isCorrectAnswer}
              isManualScoring={isManualScoring}
            />
          </>
        );
      case 'number':
        return (
          <>
            <Input
              id={'answer-number'}
              type={'number'}
              {...(answerInput && {
                value: parseInt(answerInput?.toString() ?? '') ?? ''
              })}
              onKeyDown={(e) => {
                const regex = /^[0-9.]$/;
                const value = e.currentTarget.value as string;
                const navigationKeys = [
                  'Backspace',
                  'Delete',
                  'ArrowLeft',
                  'ArrowRight',
                  'Tab'
                ];
                if (navigationKeys.includes(e.key)) {
                  return;
                }

                if (e.key === '.' && value.includes('.')) {
                  e.preventDefault();
                  return;
                }

                if (!regex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => onNumericInputChangeHandler(e as any)}
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus
              isCorrectAnswer={isCorrectAnswer}
              isManualScoring={isManualScoring}
            />
          </>
        );
      case 'textarea':
        return (
          <>
            <TextArea
              id={'answer-textarea'}
              value={answerInput}
              rows={20}
              onChange={onTextAreaInputChangeHandler}
              placeholder={
                generateTitleById(
                  '9218c923-0be1-47f8-ae56-565694b5de33',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Type your answer here'
              }
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus
              isCorrectAnswer={isCorrectAnswer}
              isManualScoring={isManualScoring}
            />
          </>
        );
      case 'time':
        return (
          <>
            <TimePicker
              id={'answer-time'}
              value={
                answerInput !== undefined &&
                answerInput !== '' &&
                DateTime.fromISO(answerInput.toString()).isValid
                  ? DateTime.fromISO(answerInput.toString()).toJSDate()
                  : null
              }
              onChange={onTimePickerChangeHandler}
              className={
                isCorrectAnswer === undefined
                  ? 'border-dark'
                  : isCorrectAnswer
                    ? 'border-success'
                    : 'border-danger'
              }
            />
            <AnswerStatus
              isCorrectAnswer={isCorrectAnswer}
              isManualScoring={isManualScoring}
            />
          </>
        );
      default: // 'none'
        return <p id={'answer-none'}>{answerInput}</p>;
    }
  };

  return (
    <div
      id={`answer-type-${answerType}`}
      className={`answer-container h-100 w-100 overflow-y-auto overflow-x-hidden ${
        answerType === 'checkbox' || answerType === 'radio'
          ? 'min-h-10'
          : 'min-h-3'
      }`}>
      {renderAnswer()}
    </div>
  );
};

export default Answer;
