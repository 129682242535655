import React from 'react';
import { useGame } from '../hooks/use-game';
import { useGameDocument } from '../hooks/use-game-document';
import { GameStateProvider } from './game';
import { GameDocumentProvider } from './game-document';
import { ChatProvider } from './chat';
import { NotificationProvider } from './notification';
import { FacilitatorPlayerChatProvider } from './facilitator-player-chat';
import { useGameTheme } from '../hooks/use-game-theme';

interface FacilitatorProviderProps {
  gameCode: string;
  children: React.ReactNode;
}

export const FacilitatorProvider = ({
  gameCode,
  ...props
}: FacilitatorProviderProps) => {
  // prefetch all data
  const game = useGame(gameCode);
  const gameDocument = useGameDocument(gameCode);
  const gameTheme = useGameTheme(gameCode);

  const isLoading =
    game.isLoading || gameDocument.isLoading || gameTheme.isLoading;

  // return loading state
  if (isLoading) return <>Loading...</>;

  // return context wrapper
  return (
    <GameStateProvider gameCode={gameCode!} isFromFacilitator={true}>
      <GameDocumentProvider gameCode={gameCode!}>
        <ChatProvider>
          <NotificationProvider>
            <FacilitatorPlayerChatProvider gameCode={gameCode!}>
              {props.children}
            </FacilitatorPlayerChatProvider>
          </NotificationProvider>
        </ChatProvider>
      </GameDocumentProvider>
    </GameStateProvider>
  );
};
