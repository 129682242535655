import { Outlet, useParams } from 'react-router-dom';
import { GameDocumentProvider } from '../contexts/game-document';
import { PlayerStateProvider } from '../contexts/player';
import { GameStateProvider } from '../contexts/game';
import { DisplayLanguageProvider } from '../contexts/display-languages';

/**
 * The layout for pre-game screens.
 */
export const PreGameLayout = () => {
  const { gameCode } = useParams();
  return (
    <GameStateProvider gameCode={gameCode!}>
      <GameDocumentProvider gameCode={gameCode!}>
        <PlayerStateProvider gameCode={gameCode!}>
          <DisplayLanguageProvider>
            <div className={'layout layout--pre-game'}>
              <div className={'layout__container'}>
                <Outlet />
              </div>
            </div>
          </DisplayLanguageProvider>
        </PlayerStateProvider>
      </GameDocumentProvider>
    </GameStateProvider>
  );
};

export default PreGameLayout;
