import { useState, useEffect } from 'react';
import MenuItem from '../../components/menu-item';
import { useContext } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { ResourceGetById } from '../../utils/game-document/resources';
import { GameContext } from '../../contexts/game';
import {
  FacilitatorPlayerChatContext,
  FacilitatorPlayerChatContextProps
} from '../../contexts/facilitator-player-chat';
import { useAuth } from 'react-oidc-context';
import { LayoutContext } from '../../contexts/layout-provider';
type MenuProps = {
  gameCode: string;
};

const FacilitatorMenu = ({ ...props }: MenuProps) => {
  const auth = useAuth();
  const facilitatorId = auth.user?.profile.sub;
  const layout = useContext(LayoutContext);
  const [game] = useContext(GameContext);
  const { newChat } = useContext(
    FacilitatorPlayerChatContext
  ) as FacilitatorPlayerChatContextProps;

  return (
    <aside
      className={`app-side-nav ${!layout.navIsExpanded ? 'shrinked' : ''}`}>
      <div
        className={`d-flex justify-content-center cg-logo ${!layout.navIsExpanded ? 'globe' : ''}`}></div>

      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={``} icon={'home'} label={'Overview'} />
        {game.activityState && game.activityState.hasGame && <MenuItem to={`task-scoring`} icon={'tour'} label={'Task Scoring'} hasNotification={game.gameState?.hasNewSubmittedAnswer} />}
        <MenuItem to={`players`} icon={'person'} label={'Players'} />
        <MenuItem to={`groups`} icon={'group_work'} label={'Groups'} />
        <MenuItem to={`teams`} icon={'group'} label={'Teams'} />
        <MenuItem to={`chat`} icon={'chat_bubble'} label={'Chat'} hasNotification={newChat.filter(chat => chat.author.id !== facilitatorId).length > 0} />
        {game.activityState && game.activityState.hasGame && <MenuItem to={`map-view`} icon={'map'} label={'Map View'} />}
        {game.activityState && game.activityState.hasGame && <MenuItem to={`push-content`} icon={'folder'} label={'Push Content'} />}
        {game.activityState && game.activityState.hasGame && <MenuItem to={`all-tasks`} icon={'check_circle'} label={'All Tasks'} />}
        {game.activityState && game.activityState.hasFeedback &&  <MenuItem to={`feedback`} icon={'add_reaction'} label={'Feedback'} /> }
       
      </div>
      <hr />

      <div
        className={`d-flex aside-menu__footer  ${!layout.navIsExpanded ? 'justify-content-center' : 'flex-row-reverse'}`}>
        <a
          href="#"
          onClick={layout.toggleNavIsExpanded}
          className="aside-menu__expand">
          <span className="material-symbols-outlined">{`${!layout.navIsExpanded ? 'double_arrow' : 'keyboard_double_arrow_left'}`}</span>
        </a>
      </div>
    </aside>
  );
};

export default FacilitatorMenu;
