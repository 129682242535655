import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { FacilitatorState } from '../../components/facilitator/facilitator-state';
import { GameContext } from '../../contexts/game';
import {
  getPlayers,
  getPlayerState,
  submitTaskAnswerScoringAsync
} from '../../services/players';
import { PlayerResponse } from '../../types/responses/player-response';

import {
  getTeamList,
  getTeamSelectedAsync,
  joinTeam,
  leaveTeam
} from '../../services/teams';
import {
  GetTeamSelectedResponse,
  TeamResponse
} from '../../types/responses/team-response';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { PlayerContextState } from '../../contexts/player';

import { TabButtonComponent } from '../../components/tabs/tab-button';
import { TaskContentAnswerEntity, TaskEntity } from '../../types';
import { TaskCard } from '../../components/facilitator/task/task-card';
import { UpdatePlayerStateAsync } from '../../utils/game-engine/base';
import { ManualScoring } from '../../types/manual-scoring';
import { Button } from '@progress/kendo-react-buttons';
import { AllPlayerFacilitatorState } from '../../features/facilitator/player/all-player-state';
import HelpSupport from '../../components/help-support';

export const PlayersPage = () => {
  const [game] = useContext(GameContext);
  const [notification, setNotification] = useContext(NotificationContext);
  const [players, setPlayers] = useState<PlayerResponse[]>([]);
  const [playerState, setPlayerState] = useState<PlayerContextState>();
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerResponse>();
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<GetTeamSelectedResponse>();
  const [selectedTeamCode, setSelectedTeamCode] = useState<string>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tabs = ['Details', 'Tasks'];
  const allPlayerCode = 'all_player';

  const getAllPlayers = async () => {
    let playersResult = (await getPlayers(game?.gameCode!)) as PlayerResponse[];
    playersResult = playersResult.filter((item) => item.activityTypeId === 1);

    if (playersResult.length > 0) {
      playersResult.unshift({
        activityTypeId: 0,
        name: 'All Players',
        code: allPlayerCode
      });

      setSelectedPlayer(playersResult[0]);
    }

    setPlayers(playersResult);
  };

  const getAllTeams = async () => {
    let teamResult = (await getTeamList(game?.gameCode!)) as TeamResponse[];
    setTeams(teamResult);
  };

  const onClickHandler = async (player: PlayerResponse) => {
    setSelectedPlayer(player);
  };

  const getPlayerStateAsync = async () => {
    if (selectedPlayer) {
      setSelectedTeam({});
      setSelectedTeamCode('');
      try {
        let playerStateResponse;
        playerStateResponse = await getPlayerState(
          game?.gameCode!,
          selectedPlayer?.code!
        );

        if (
          game.gameState?.status !== 'Finished' &&
          game.activityState?.hasGame
        ) {
          setPlayerState(playerStateResponse);
        }

        let teamResult = (await getTeamSelectedAsync(
          game?.gameCode!,
          playerStateResponse?.playerState?.teamCode ?? ''
        )) as GetTeamSelectedResponse;
        if (teamResult.code) {
          setSelectedTeam(teamResult);
          setSelectedTeamCode(teamResult.code!);
        } else {
          setSelectedTeam({});
          setSelectedTeamCode('');
        }
      } catch (error) {
        setPlayerState(undefined);
      }
    }
  };

  const handleChangeTeam = (team: TeamResponse) => {
    setSelectedTeamCode(team ? team.teamCode! : '');
  };

  const responseTeamNotification = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const handleAddPlayerToTeam = () => {
    joinTeam(selectedTeamCode!, selectedPlayer?.code!, game?.gameCode!)
      .then((response) => {
        if (response) {
          responseTeamNotification('Success add player to a team ');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemovePlayerFromTeam = () => {
    leaveTeam(selectedTeamCode!, selectedPlayer?.code!, game?.gameCode!)
      .then((response) => {
        if (response) {
          responseTeamNotification('Player has been removed from team ');
          setSelectedTeam({});
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updatePlayerStateTaskScore = (scoring: ManualScoring) => {
    if (playerState) {
      setPlayerState((prevPlayerState) => {
        // Find the task
        const tasks = playerState?.playerState?.tasks;
        const taskIndex = tasks?.findIndex((x) => x.id === scoring.taskId);

        if (taskIndex === -1 || taskIndex === undefined) return prevPlayerState; // Task not found

        // Find the form index
        const taskContentFormAnswers =
          tasks![taskIndex]?.taskContentFormAnswers;
        const formIndex = taskContentFormAnswers?.findIndex(
          (x) => x.formId === scoring.formId
        );

        if (formIndex === -1 || formIndex === undefined) return prevPlayerState; // Form not found

        // Update the score immutably
        let newPlayerStates = { ...prevPlayerState };
        let newTasks = [...newPlayerStates.playerState!.tasks!];
        let newFormAnswers = [...newTasks[taskIndex].taskContentFormAnswers!];

        newFormAnswers[formIndex] = {
          ...newFormAnswers[formIndex],
          score: scoring.score
        };

        newTasks[taskIndex] = {
          ...newTasks[taskIndex],
          taskContentFormAnswers: newFormAnswers
        };

        newPlayerStates = {
          ...newPlayerStates,
          playerState: {
            ...newPlayerStates.playerState!,
            tasks: newTasks
          }
        };

        return UpdatePlayerStateAsync(
          prevPlayerState!,
          newPlayerStates.playerState!
        );
      });
    }
  };

  const submitScoring = async (scoring: any) => {
    try {
      await submitTaskAnswerScoringAsync(scoring);
    } catch (error) {
      console.error('Failed submit scoring');
    } finally {
      updatePlayerStateTaskScore(scoring);
    }
  };

  const reloadHandler = () => {
    try {
      setIsLoading(true);
      getPlayerStateAsync();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPlayerDetail = () => {
    if (players.length > 0 && !selectedPlayer) {
      return <h3>Please select a player</h3>;
    }
    if (players.length > 0 && selectedPlayer) {
      return (
        <FacilitatorState
          isPlayer={true}
          isGroup={false}
          gameCode={game?.gameCode!}
          playerOrTeamCode={selectedPlayer?.code!}
          teamCode={selectedTeamCode}
          state={playerState?.playerState}
          player={selectedPlayer}
          isDisableConfirm={
            game.gameState?.status === 'Finished' ||
            !game.activityState?.hasGame
          }
          nameState={selectedPlayer?.name}
          teamList={teams}
          selectedTeam={selectedTeam}
          handleChangeTeam={handleChangeTeam}
          handleAddPlayerToTeam={handleAddPlayerToTeam}
          handleRemovePlayerFromTeam={handleRemovePlayerFromTeam}
        />
      );
    }
  };

  useEffect(() => {
    getAllPlayers();
    getAllTeams();
  }, []);

  useEffect(() => {
    getPlayerStateAsync();
  }, [selectedPlayer]);

  return (
    <>
      <HelpSupport
        title="Managing players"
        url="https://forum.catalystglobal.com/t/4649"
      />
      {isLoading && (
        <div
          className={
            'd-flex flex-column align-items-center justify-content-center h-100 w-100 gap-2'
          }>
          <span className={'loader'}></span>
          <label className={'text-gray'}>Loading...</label>
        </div>
      )}
      {players.length === 0 && <h3>No players found</h3>}

      <div className={'d-flex flex-wrap'}>
        <div className={'d-flex flex-column group-menu'}>
          <div className={'border-list'}>
            <ul className="list-group facilitator-border-none p-2">
              {players?.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="list-group-item cursor-pointer"
                    onClick={() => onClickHandler(item)}>
                    <div className={'d-flex justify-content-between'}>
                      <small
                        className={`${
                          item.code === selectedPlayer?.code
                            ? 'fw-bold text-dark'
                            : 'text-dark'
                        }`}>
                        {item?.name}
                      </small>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className={'player-content '}>
          {selectedPlayer && selectedPlayer.code === allPlayerCode && (
            <Row className={'ml-3 h-100'}>
              <Row className={'container-tab-component'}>
                <TabButtonComponent
                  thisTabIndex={-1}
                  activeTab={-1}
                  title={'Details'}
                  onSetActiveTab={(tabIndex) => {}}
                />
              </Row>
              <Row className="h-100">
                <AllPlayerFacilitatorState
                  isDisableConfirm={
                    game.gameState?.status === 'Finished' ||
                    !game.activityState?.hasGame
                  }
                />
              </Row>
            </Row>
          )}

          {selectedPlayer && selectedPlayer.code !== allPlayerCode && (
            <Row className={'ml-3'}>
              <Row className={'container-tab-component'}>
                {tabs.map((item, index) => (
                  <TabButtonComponent
                    key={item}
                    thisTabIndex={index}
                    activeTab={activeTab}
                    title={item}
                    onSetActiveTab={(tabIndex) => {
                      setActiveTab(tabIndex);
                    }}
                  />
                ))}
                <div
                  onClick={reloadHandler}
                  className={
                    'd-flex align-items-center text-info cursor-pointer'
                  }>
                  <span className="material-symbols-outlined">sync</span>
                </div>
              </Row>
              <hr className="mt-0" />
              <Row>
                {activeTab === 0 && renderPlayerDetail()}
                {activeTab === 1 && (
                  <Row>
                    {playerState?.playerState?.tasks?.map(
                      (task: TaskEntity, taskIndex: number) => {
                        return task?.taskContentFormAnswers?.map(
                          (
                            taskContentAnswer: TaskContentAnswerEntity,
                            taskContentAnswerIndex: number
                          ) => {
                            return (
                              <TaskCard
                                key={`task-card-${taskContentAnswerIndex}`}
                                taskContentAnswer={taskContentAnswer!}
                                index={taskContentAnswerIndex}
                                selectedTask={task!}
                                playerName={playerState?.playerState?.name}
                                playerCode={playerState?.playerState?.code}
                                isEdit={false}
                                onSubmitScore={submitScoring}
                              />
                            );
                          }
                        );
                      }
                    )}
                  </Row>
                )}
              </Row>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};
