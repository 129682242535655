import { GameDocument } from '../../../types/game-document';

export const GetImageOverlay = (
  gameDocument: GameDocument,
  zoneId?: string
) => {
  if (zoneId) {
    const mapZoneId = gameDocument.rules.worldMap.zones.find(
      (zone) => zone.zoneAssId === zoneId
    )?.mapAssId;
    if (mapZoneId) {
      return gameDocument.assets.maps?.find((map) => map.id === mapZoneId)
        ?.imageOverlay;
    }
  }

  return gameDocument.assets.maps?.find((map) => map.name === 'world-map')
    ?.imageOverlay;
};
