import { useContext, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import HelpSupport from '../help-support';
import SimpleEntity from '../tabs/simple-entity';
import { GameContext } from '../../contexts/game';
import { addNewTeams } from '../../services/teams';

interface CreateTeamProps {
  onSubmitClick?: () => void;
}

export const CreateTeam = ({ onSubmitClick = () => {} }: CreateTeamProps) => {
  const [game] = useContext(GameContext);
  const [teamDialogIsVisible, setTeamDialogIsVisible] =
    useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [notification, setNotification] = useContext(NotificationContext);

  const toggleTeamsDialogHandler = () => {
    if (teamDialogIsVisible) {
      setError('');
    }
    setTeamDialogIsVisible((prevState) => !prevState);
  };

  const responseGroupTeamNotification = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onSubmitTeamHandler = (name?: string, avatar?: string) => {
    let errorMessage = '';
    if (name === '') {
      errorMessage = 'Team name is required';
    } else if (name && (name.length < 2 || name.length > 128)) {
      errorMessage = 'Please enter a team name that is 2 - 128 characters';
    }

    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    addNewTeams(game.gameCode!, name!, avatar)
      .then((response) => {
        if (response) {
          onSubmitClick();
          responseGroupTeamNotification(
            'Team successfully added, please wait for team synchronization'
          );
        }
        toggleTeamsDialogHandler();
      })
      .catch(() => {
        setError('Team name already exists');
      });
  };

  return (
    <>
      <div className={'d-flex justify-content-end w-full'}>
        <HelpSupport
          title="Creating & managing teams"
          url="https://forum.catalystglobal.com/t/4667"
        />
        <Button
          themeColor={'success'}
          className={'ml-3'}
          onClick={toggleTeamsDialogHandler}>
          Create team
        </Button>
      </div>
      {teamDialogIsVisible && (
        <SimpleEntity
          inputTitle={'Team name'}
          errorMessage={error}
          onToggleProfileWidget={() => {
            toggleTeamsDialogHandler();
            setError('');
          }}
          onSubmit={onSubmitTeamHandler}
        />
      )}
    </>
  );
};
