import React, { useContext, useState } from 'react';
import { kebabCase } from 'lodash';
import { LinkProps, NavLink, useParams } from 'react-router-dom';
import { LayoutContext } from '../contexts/layout-provider';

interface MenuItemProps extends LinkProps {
  icon: string;
  label: string;
  end?: boolean;
  expanded?: boolean;
  hasNotification?: boolean;
  iconSet?: string | 'outlined' | 'light';
}

const MenuItem = ({
  icon,
  label,
  children,
  to,
  end,
  expanded = false,
  iconSet = 'outlined',
  hasNotification = false,
  ...props
}: MenuItemProps) => {
  const layout = useContext(LayoutContext);
  const params = useParams();
  const activeMenu = React.useMemo(() => {
    return to === kebabCase(params?.['*']?.replaceAll('facilitator', ''));
  }, [params, to]);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const chevronIcon = isExpanded ? 'expand_more' : 'chevron_right';
  const chevronClass = isExpanded
    ? 'aside-menu__sub--expanded'
    : 'aside-menu__sub--collapsed';

  return (
    <>
      <NavLink
        to={to}
        onClick={() => layout.setActiveMenu(label)}
        end={end ?? true}
        {...props}
        className={`aside-menu__item ${activeMenu ? 'fw-bold' : ''}  ${
          props.className ?? ''
        }`.trim()}>
        <span className={'item__icon'}>
          <span
            className={`material-symbols-outlined material-symbols-outlined--${iconSet}`}>
            {icon}
          </span>
        </span>
        <span className={'item__label text-dark'}>{label}</span>
        {hasNotification && (
          <span className={'k-icon k-i-circle text-danger'}></span>
        )}
        {children && (
          <span
            className={'item__chevron material-symbols-outlined'}
            onClick={() => setIsExpanded(!isExpanded)}>
            {chevronIcon}
          </span>
        )}
      </NavLink>
      {children && (
        <div className={`aside-menu__sub ${chevronClass}`}>{children}</div>
      )}
    </>
  );
};

export default MenuItem;
