import React, { JSX, useState } from 'react';

export interface AchievementContent {
  message: JSX.Element | string;
  isHide: boolean;
  imageUrl?: string;
  isPushNotification?: boolean;
  priorityQueueNumber?: number;
  onCloseCallback?: () => void; // if someone need callback once AchievementContent vanish or user click close button
}

interface AchievementState {
  content: AchievementContent[];
}

const initialAchievementState: AchievementState = {
  content: []
};

const AchievementContext = React.createContext<
  [
    initialState: AchievementState,
    setState: React.Dispatch<React.SetStateAction<AchievementState>>
  ]
>([initialAchievementState, () => {}]);

const AchievementProvider = (props: any) => {
  const [state, setState] = useState<AchievementState>({
    ...initialAchievementState
  });

  return (
    <AchievementContext.Provider value={[state, setState]}>
      {props.children}
    </AchievementContext.Provider>
  );
};

export { AchievementContext, AchievementProvider };
