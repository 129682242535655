import { useContext } from 'react';
import ScoreList from '../../components/leaderboard/score-list';
import { GameContext } from '../../contexts/game';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';

const hideAvatarGameList: string[] = ['AR Pyramid Puzzle'];

const LeaderboardPage = () => {
  const [state] = useContext(GameContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  return (
    <div className={'text-center mt-10 mb-8'}>
      <h1 className={'text-primary'}>
        <b>
          {generateTitleById(
            'e698200c-73e4-41e3-b4b1-fa4b38f4638c',
            gameDocument,
            displayLanguageContext.displayLanguageSelected.resources!,
            'game'
          ) || 'Leaderboard'}
        </b>
      </h1>
      <hr />
      <ScoreList
        isHideAvatar={hideAvatarGameList.includes(
          gameDocument.gameDocument?.name!
        )}
        data={state.gameState?.players}
      />
    </div>
  );
};

export default LeaderboardPage;
