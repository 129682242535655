import React from 'react';
import { StackLayout } from '@progress/kendo-react-layout';
import { PlayerState } from '../../types/state';
import ScoreCard from './score-card';

interface ScoreListProps {
  data?: PlayerState[];
  isHideAvatar?: boolean;
}

const ScoreList = ({ data = [], isHideAvatar = false }: ScoreListProps) => {
  const scoreCards = data
    .sort((a: PlayerState, b: PlayerState) => b.score! - a.score!)
    .map((player: PlayerState, index: number) => (
      <ScoreCard
        key={index}
        rank={index + 1}
        avatarImage={player.avatarImage}
        name={player.name}
        score={player.score}
        isHideAvatar={isHideAvatar}
      />
    ));

  return (
    <StackLayout orientation={'vertical'} className={'w-100'}>
      {scoreCards}
    </StackLayout>
  );
};

export default ScoreList;
