import React, { useEffect } from 'react';
import { FilterDescriptor } from '@progress/kendo-data-query';
import {
  ComboBoxFilterChangeEvent,
  MultiColumnComboBox,
  MultiColumnComboBoxProps
} from '@progress/kendo-react-dropdowns';
import { CountryPhonecodeResponse } from '../../types/responses/country-phonecode-response';
import { GetCountryPhonecodeAsync } from '../../services/country';

const delay: number = 500;

const CountryCodeCombobox: React.FC<MultiColumnComboBoxProps> = (props) => {
  const countryCodes = React.useRef<CountryPhonecodeResponse[]>([]);
  const [data, setData] = React.useState<CountryPhonecodeResponse[]>([]);
  const [loading, setLoading] = React.useState(false);

  // load the initial set of countries
  useEffect(() => {
    // cache hit
    if (countryCodes.current) {
      setData(countryCodes.current);
      setLoading(false);
      return;
    }
    // fetch new data
    GetCountryPhonecodeAsync().then((res) => {
      countryCodes.current = res.data;
      setData(res.data);
      setLoading(false);
    });
  }, []);

  // allow filtering of the country data
  const filterData = (filter: FilterDescriptor) => {
    const slice = countryCodes.current!.slice();
    return filter.value === ''
      ? slice
      : slice.filter(
          (e) =>
            e.code.includes(filter.value) ||
            e.country
              .toLowerCase()
              .includes(
                filter.value.toLowerCase() ||
                  e.countryIso
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
              )
        );
  };

  // debounce 500ms so filtering doesnt take affect after every keystroke
  const timeout = React.useRef<any>(null);
  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setData(filterData(event.filter));
      setLoading(false);
    }, delay);
    setLoading(true);
  };

  return (
    <MultiColumnComboBox
      data={data}
      loading={loading}
      filterable={true}
      onFilterChange={filterChange}
      {...props}
    />
  );
};

export default CountryCodeCombobox;
