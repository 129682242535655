import React from 'react';
import { Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat, toLonLat, transform } from 'ol/proj';

import { GameContext } from '../contexts/game';
import { TeamContext } from '../contexts/team';
import {
  createCustomPlayerPosition,
  createOtherPlayersItem
} from '../utils/game-engine/player-position';
import { PlayerContext } from '../contexts/player';
import { MapContext } from '../components/map/map-context';
import { GetTaskById } from '../utils/game-document/assets';
import { GameDocumentContext } from '../contexts/game-document';
import { getPlayerLocationStatus } from '../utils/game-document/location';
import { MapIllustrationContext } from '../components/map/map-illustration-context';

export const useGamePlayerPosition = ({ mapType }: { mapType: string }) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [game] = React.useContext(GameContext);
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [player] = React.useContext(PlayerContext);
  const [team] = React.useContext(TeamContext);

  const mapContext = React.useMemo(
    () => (mapType === 'illustration' ? mapIllustration : map),
    [mapType, map, mapIllustration]
  );

  //#region Add player element and its overlay
  const addPlayerOverlay = React.useCallback(
    (
      id: string,
      name: string,
      avatarUrl: string,
      isFacilitator: boolean = false
    ) => {
      if (mapContext) {
        const playerExist = mapContext.getOverlayById(id);
        // prevent create player overlay more than once
        if (playerExist) return;

        const playerElement = createCustomPlayerPosition({
          id,
          name,
          score: 0,
          avatarUrl,
          inGameSettings: gameDocument.gameDocument?.settings.inGame,
          isFacilitator
        });

        const overlay = new Overlay({
          id,
          // initial position 0,0
          position: fromLonLat([0, 0]),
          positioning: 'center-center',
          element: playerElement
        });

        mapContext.addOverlay(overlay);
      }
    },
    [mapContext, gameDocument.gameDocument?.settings.inGame]
  );

  //#region Update player overlay by its id
  const updatePlayerOverlayPosition = React.useCallback(
    (id: string, newPosition: Coordinate | [number, number]) => {
      const player = mapContext.getOverlayById(id);
      if (player) {
        player.setPosition(fromLonLat(newPosition));
      }
    },
    [mapContext]
  );

  const updatePlayerOverlayContent = React.useCallback(
    (
      code: string,
      avatar: string,
      name: string,
      score: number,
      playerStatus: 'offline' | 'available' | 'away',
      team?: string
    ) => {
      const playerContainer = document.querySelectorAll(
        `[id^="playerContainer-${code}"]`
      );
      for (let i = 0; i < playerContainer.length; i++) {
        const playerAvatar = playerContainer[i].querySelector(
          '.avatar-img'
        ) as HTMLImageElement;
        if (playerAvatar) {
          playerAvatar.src = avatar;
        }

        const playerName = playerContainer[i].querySelector(
          `#playerName-${code}`
        ) as HTMLSpanElement;
        if (playerName) {
          playerName.innerHTML = name;
          const container = playerName.parentElement?.parentElement;
          container && container.classList.add(playerStatus);
        }

        const playerTeam = playerContainer[i].querySelector(
          `#playerTeam-${code}`
        ) as HTMLSpanElement;
        if (playerTeam) {
          if (team) {
            playerTeam.className = '';
            playerTeam.innerHTML = team;
          } else {
            playerTeam.className = 'd-none';
          }
        }

        const playerScore = playerContainer[i].querySelector(
          `#playerScore-${code}`
        ) as HTMLSpanElement;
        if (playerScore) {
          playerScore.innerHTML = score.toString();
        }
      }
    },
    []
  );

  //#region Add me to map
  const addMine = React.useCallback(() => {
    if (mapContext && player.playerState) {
      const { code, name, avatarImage } = player.playerState;
      if (code && name && avatarImage) {
        addPlayerOverlay(code, name, avatarImage);
      }
    }
  }, [mapContext, player.playerState, addPlayerOverlay]);

  //#region Add and update other players to map
  const addAllPlayer = React.useCallback(
    (isFacilitator = false) => {
      if (game.gameState?.players.length) {
        game.gameState.players
          .filter((e) => e.code !== player.playerState?.code)
          .forEach((player) => {
            const {
              code,
              name,
              avatarImage,
              score,
              teamCode,
              coordinates,
              lastUpdated
            } = player;
            if (code && name && avatarImage) {
              addPlayerOverlay(code, name, avatarImage, isFacilitator);
            }

            if (code) {
              if (coordinates?.longitude && coordinates.latitude) {
                const playerCoordinates = transform(
                  [coordinates.longitude, coordinates.latitude],
                  'EPSG:4326',
                  'EPSG:3857'
                );
                updatePlayerOverlayPosition(code, toLonLat(playerCoordinates));
              }

              if (
                code !== undefined &&
                avatarImage !== undefined &&
                name !== undefined &&
                score !== undefined
              ) {
                let teamName: string | undefined = '';
                if (teamCode) {
                  teamName = game.gameState?.teams.find(
                    (team) => team.code === teamCode
                  )?.name;
                }
                const playerStatus = getPlayerLocationStatus(lastUpdated);
                updatePlayerOverlayContent(
                  code,
                  avatarImage,
                  name,
                  score,
                  playerStatus,
                  teamName
                );
              }
            }
          });
      }
    },
    [
      game.gameState?.teams,
      game.gameState?.players,
      player.playerState?.code,
      addPlayerOverlay,
      updatePlayerOverlayContent,
      updatePlayerOverlayPosition
    ]
  );

  //#region Update other players position to latest completed task on illustration map
  const addAllPlayerOnIllustration = (isFacilitator: boolean = false) => {
    if (mapType !== 'illustration') return;

    if (
      !gameDocument.gameDocument?.settings.inGame.showOtherPlayerOrTeamOnMap &&
      !isFacilitator
    )
      return;

    // Get filtered list of other players with their info
    const otherPlayers =
      game.gameState?.players
        .filter((e) => e.code !== player?.playerState?.code)
        .map((player) => {
          const team = player.teamCode
            ? game.gameState?.teams.find((t) => t.code === player.teamCode)
                ?.name || ''
            : '';

          return {
            id: player.code,
            avatarUrl: player.avatarImage,
            name: player.name,
            latestCompletedTaskId: player.latestCompletedTaskId,
            team
          };
        }) || [];

    // Get unique task ids completed by other players
    const completedTaskIds = Array.from(
      new Set(
        otherPlayers
          .map((p) => p.latestCompletedTaskId)
          .filter((id): id is string => typeof id === 'string')
      )
    );

    // Hide containers for tasks not in completedTaskIds
    mapContext
      .getOverlays()
      .getArray()
      .forEach((overlay) => {
        const id = overlay.getId()?.toString();
        if (!id?.startsWith('illustration-players-container-')) {
          return;
        }

        const taskId = id.replace('illustration-players-container-', '');
        const playersContainer = overlay.getElement();
        const otherPlayersContainer = playersContainer?.querySelector(
          '.other-players-container'
        );

        if (!completedTaskIds.includes(taskId)) {
          otherPlayersContainer?.classList.add('d-none');
        }
      });

    // Update UI for tasks with completed players
    completedTaskIds.forEach((taskId) => {
      const container = document.getElementById(
        `otherPlayersContainer-${taskId}`
      );
      const counter = document.getElementById(`otherPlayersCounter-${taskId}`);
      const playerList = document.getElementById(`otherPlayersList-${taskId}`);

      const completedPlayers = otherPlayers.filter(
        (p) => p.latestCompletedTaskId === taskId
      );

      if (completedPlayers.length === 0) {
        return;
      }

      container?.classList.remove('d-none');
      if (counter) {
        counter.innerText = completedPlayers.length.toString();
      }

      if (playerList) {
        const playerElements = completedPlayers.map((player) => {
          const teamElement = document.getElementById(
            `playerTeam-${taskId}`
          ) as HTMLSpanElement;
          if (teamElement) {
            if (player.team) {
              teamElement.classList.remove('d-none');
              teamElement.innerHTML = player.team;
            } else {
              teamElement.classList.add('d-none');
            }
          }

          return createOtherPlayersItem({
            id: player.id || '',
            avatarUrl: player.avatarUrl || '',
            name: player.name || '',
            team: player.team || ''
          });
        });

        playerList.replaceChildren(...playerElements);
      }
    });
  };

  //#region Update my position to latest completed task on illustration map
  React.useEffect(() => {
    // Early return if conditions not met
    if (
      mapType !== 'illustration' ||
      !player.playerState ||
      !player.playerState?.latestCompletedTaskId ||
      gameDocument.gameDocument?.settings.inGame.tasksRequireProximity
    )
      return;

    const taskId = player.playerState.latestCompletedTaskId;
    const task = GetTaskById(gameDocument.gameDocument, taskId);

    if (!task?.boundary?.geometry.coordinates) return;

    // Update player containers visibility
    mapContext
      .getOverlays()
      .getArray()
      .forEach((overlay) => {
        const id = overlay.getId()?.toString();
        if (!id?.startsWith('illustration-players-container-')) return;

        const currentTaskId = id.replace('illustration-players-container-', '');
        const playerContainer = overlay
          .getElement()
          ?.querySelector('.player-container');

        if (!playerContainer) return;

        // Hide all containers by default, show only for current task
        playerContainer.classList.toggle('d-none', currentTaskId !== taskId);
      });
  }, [
    mapType,
    mapContext,
    player.playerState,
    gameDocument.gameDocument?.settings.inGame.tasksRequireProximity
  ]);

  return {
    addMine,
    addAllPlayer,
    addAllPlayerOnIllustration,
    updatePlayerOverlayContent,
    updatePlayerOverlayPosition
  };
};
