import React, { useContext, useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import ImageLayer from 'ol/layer/Image.js';
import Projection from 'ol/proj/Projection.js';
import Static from 'ol/source/ImageStatic.js';
import View from 'ol/View.js';
import { getCenter } from 'ol/extent.js';
import VectorSource from 'ol/source/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { GameDocumentContext } from '../../../contexts/game-document';
import { GetResourceValue } from '../../../utils/game-document/resources';
import {
  GetMapById,
  GetMapByZoneId
} from '../../../utils/game-document/assets';
import { MapIllustrationContext } from '../../../components/map/map-illustration-context';

interface FacilitatorIllustrationMapProps {
  selectedMap?: string;
  geoJSON?: any;
  setIsIllustrationMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ImageResolution {
  height: number;
  width: number;
}

const FacilitatorIllustrationMap: React.FC<FacilitatorIllustrationMapProps> = ({
  geoJSON,
  selectedMap,
  setIsIllustrationMapLoaded
}) => {
  const mapIllustration = useContext(MapIllustrationContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const ref = useRef<HTMLDivElement>(null);
  const vectorSourceRef = useRef<VectorSource>(new VectorSource());
  const imageLayerRef = useRef<ImageLayer<Static> | null>(null);
  const [mapURL, setMapURL] = useState<string>('');

  const getImage = (url: string): Promise<ImageResolution> => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        const imgRes: ImageResolution = {
          width: img.width,
          height: img.height
        };
        resolve(imgRes);
      };
      img.onerror = (e) => {
        reject(e);
      };
    });
  };

  const getMapURL = () => {
    if (selectedMap === 'world-map') {
      let worldMap = GetMapById(
        gameDocument!.gameDocument!,
        gameDocument!.gameDocument!.rules!.worldMap!.mapAssId!
      );

      let imgUrl = GetResourceValue(
        gameDocument!.gameDocument!,
        worldMap!.imageResId!,
        ''
      );
      getImage(imgUrl);
      setMapURL(imgUrl);
    } else {
      let zoneMap = GetMapByZoneId(gameDocument!.gameDocument!, selectedMap!);
      let imgUrl = GetResourceValue(
        gameDocument!.gameDocument!,
        zoneMap!.imageResId!,
        ''
      );

      getImage(imgUrl);
      setMapURL(imgUrl);
    }
  };

  useEffect(() => {
    getMapURL();
  }, [selectedMap]);

  useEffect(() => {
    getImage(mapURL)
      .then((res) => {
        let map =
          selectedMap === 'world-map'
            ? GetMapById(
                gameDocument!.gameDocument!,
                gameDocument!.gameDocument!.rules!.worldMap!.mapAssId!
              )
            : GetMapByZoneId(gameDocument!.gameDocument!, selectedMap!);

        const extent = [0, 0, res!.width, res!.height];
        const projection = new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent
        });

        if (ref.current) {
          // Initialize the map
          imageLayerRef.current = new ImageLayer<Static>({
            source: new Static({
              attributions: '',
              url: mapURL,
              projection: projection,
              imageExtent: extent
            })
          });

          mapIllustration.setView(
            new View({
              projection: projection,
              center:
                map!.latitude !== undefined && map!.longitude !== undefined
                  ? [map!.longitude, map!.latitude]
                  : getCenter([0, 0, res!.width, res!.height]),
              extent,
              zoom: 1
            })
          );

          const layers = mapIllustration.getAllLayers();
          mapIllustration.setTarget(ref.current);
          layers[0] = imageLayerRef.current;
          mapIllustration.setLayers(layers);

          setIsIllustrationMapLoaded(true);
        } else if (imageLayerRef.current) {
          imageLayerRef.current.setSource(
            new Static({
              attributions: '',
              url: mapURL,
              projection: projection,
              imageExtent: [0, 0, res!.width, res!.height]
            })
          );
        }
      })
      .finally(() => {});
  }, [mapURL, mapIllustration, imageLayerRef, ref]);

  useEffect(() => {
    if (mapIllustration) {
      const clickListener = () => {
        const taskList = document.querySelectorAll(
          '[id^="otherPlayersContainer-"]'
        );
        taskList.forEach((list) => {
          list
            .querySelector('[id^="otherPlayersList-"]')
            ?.classList.add('d-none');
          list.querySelector('div.text-danger')?.classList.add('d-none');
          list.querySelector('span')?.classList.remove('d-none');
          list.classList.remove('expanded');
          list.classList.add('cursor-pointer');
        });
      };

      mapIllustration.on('singleclick', clickListener);

      return () => {
        mapIllustration.un('singleclick', clickListener);
      };
    }
  }, [mapIllustration]);

  useEffect(() => {
    if (geoJSON) {
      // Clear previous features
      vectorSourceRef.current.clear();

      // Parse GeoJSON features with the correct projection
      const features = new GeoJSON().readFeatures(geoJSON, {});

      // Add new features
      vectorSourceRef.current.addFeatures(features);
    }
  }, [geoJSON]);

  return <div ref={ref} className={`layout-facilitator--mapview`} />;
};

export default FacilitatorIllustrationMap;
