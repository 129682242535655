import { InGame } from '../../types/game-document/settings/in-game';
import { moveTaskToTop } from './task-position';

export const createCustomPlayerPosition = ({
  id,
  name,
  score,
  avatarUrl,
  inGameSettings,
  isFacilitator = false,
  onIllustration = false
}: {
  id: string;
  name: string;
  score: string | number;
  avatarUrl?: string;
  inGameSettings?: InGame;
  isFacilitator?: boolean;
  onIllustration?: boolean;
}) => {
  const playerContainer = document.createElement('div');
  playerContainer.classList.add('player-container');
  onIllustration && playerContainer.classList.add('on-illustration', 'd-none');
  playerContainer.id = `playerContainer-${id}`;

  const avatarContainer = document.createElement('div');
  avatarContainer.classList.add('avatar-container');
  const avatarCircle = document.createElement('div');
  avatarCircle.classList.add('avatar-circle');

  if (avatarUrl) {
    const avatar = document.createElement('img');
    avatar.id = `avatar-${id}`;
    avatar.src = avatarUrl;
    avatar.alt = 'avatar';
    avatar.classList.add('avatar-img');
    onIllustration && avatar.classList.add('on-illustration');
    avatarCircle.append(avatar);
  }

  avatarContainer.append(avatarCircle);

  const profileContainer = document.createElement('div');
  profileContainer.classList.add('profile-container', 'hidden');

  const playerName = document.createElement('span');
  playerName.id = `playerName-${id}`;
  playerName.style.fontWeight = 'bold';
  playerName.innerHTML = name;

  const playerTeam = document.createElement('span');
  playerTeam.id = `playerTeam-${id}`;
  playerTeam.className = 'd-none';
  playerTeam.innerHTML = 'Player Team';

  const playerScore = document.createElement('span');
  playerScore.id = `playerScore-${id}`;
  playerScore.style.fontWeight = 'bold';
  playerScore.innerHTML = score.toString();

  profileContainer.append(playerName, playerTeam, playerScore);

  const clickEvent = () => {
    if (playerContainer.classList.contains('expanded')) {
      playerContainer.classList.remove('expanded');
      profileContainer.classList.add('hidden');
      profileContainer.classList.remove('visible');
    } else {
      playerContainer.classList.add('expanded');
      profileContainer.classList.add('visible');
      profileContainer.classList.remove('hidden');
    }
  };

  playerContainer.style.cursor = 'pointer';
  playerContainer.addEventListener('click', clickEvent);

  if (inGameSettings && !isFacilitator) {
    // hide player score
    // remove gap and padding if no player/team name and score
    if (!inGameSettings.showPlayerScoreOnMap) {
      profileContainer.removeChild(playerScore);
    }

    if (!inGameSettings.showPlayerDetailByClickOnMap) {
      playerContainer.removeEventListener('click', clickEvent);
    }
  }

  playerContainer.append(avatarContainer, profileContainer);
  return playerContainer;
};

export const createIllustrationPlayersContainer = (
  id: string,
  taskContainer: HTMLDivElement,
  playerItems: HTMLDivElement[]
) => {
  const playersContainer = document.createElement('div');
  playersContainer.classList.add('d-flex', 'gap-2', 'justify-content-center');

  const otherPlayersContainer = document.createElement('div');
  otherPlayersContainer.classList.add(
    'other-players-container',
    'cursor-pointer',
    'd-none'
  );
  otherPlayersContainer.id = `otherPlayersContainer-${id}`;

  const counter = document.createElement('span');
  counter.classList.add('text-center');
  counter.id = `otherPlayersCounter-${id}`;
  counter.innerHTML = `${playerItems.length}`;

  const playerList = document.createElement('div');
  playerList.classList.add(
    'd-none',
    'd-flex',
    'gap-1',
    'flex-column',
    'w-100',
    'h-100',
    'overflow-auto',
    'text-center'
  );
  playerList.id = `otherPlayersList-${id}`;

  const closeButton = document.createElement('div');
  closeButton.classList.add(
    'text-danger',
    'd-none',
    'd-flex',
    'w-100',
    'align-items-end',
    'justify-content-end',
    'cursor-pointer'
  );
  closeButton.innerHTML = 'Close';

  // Check if device supports touch events
  const isTouchDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  otherPlayersContainer.addEventListener('click', () => {
    if (isTouchDevice) moveTaskToTop(taskContainer, true);

    setTimeout(
      () => {
        counter.classList.add('d-none');
        playerList.classList.remove('d-none');
        closeButton.classList.remove('d-none');
        otherPlayersContainer.classList.add('expanded');
        otherPlayersContainer.classList.remove('cursor-pointer');
      },
      isTouchDevice ? 10 : 0
    );
  });

  if (!isTouchDevice) {
    otherPlayersContainer.addEventListener('mouseenter', () => {
      moveTaskToTop(taskContainer, true);
    });
  }

  closeButton.addEventListener('click', (e) => {
    e.stopPropagation();
    counter.classList.remove('d-none');
    playerList.classList.add('d-none');
    closeButton.classList.add('d-none');
    otherPlayersContainer.classList.remove('expanded');
    otherPlayersContainer.classList.add('cursor-pointer');
  });

  playerList.append(...playerItems);
  otherPlayersContainer.append(counter, playerList, closeButton);
  playersContainer.append(otherPlayersContainer);

  return playersContainer;
};

export const createOtherPlayersItem = ({
  id,
  avatarUrl,
  name,
  team
}: {
  id: string;
  avatarUrl: string;
  name: string;
  team?: string;
}) => {
  const playerContainer = document.createElement('div');
  playerContainer.classList.add('other-players-item');
  playerContainer.id = `otherPlayersItem${id}`;

  const avatarContainer = document.createElement('div');
  avatarContainer.classList.add('avatar-container');
  const avatarCircle = document.createElement('div');
  avatarCircle.classList.add('avatar-circle');

  if (avatarUrl) {
    const avatar = document.createElement('img');
    avatar.id = `avatar-${id}`;
    avatar.src = avatarUrl;
    avatar.alt = 'avatar';
    avatar.classList.add('avatar-img');
    avatarCircle.append(avatar);
  }

  avatarContainer.append(avatarCircle);

  const profileContainer = document.createElement('div');
  profileContainer.classList.add('profile-container');

  const playerName = document.createElement('span');
  playerName.id = `playerName-${id}`;
  playerName.style.fontWeight = 'bold';
  playerName.innerHTML = name;

  const playerTeam = document.createElement('span');
  playerTeam.id = `playerTeam-${id}`;

  team
    ? (playerTeam.innerHTML = `${team}`)
    : playerTeam.classList.add('d-none');

  profileContainer.append(playerName, playerTeam);
  playerContainer.append(avatarContainer, profileContainer);

  return playerContainer;
};
