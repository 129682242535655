import { useContext } from 'react';
import { ImageStatic } from 'ol/source';
import ImageLayer from 'ol/layer/Image';

import { MapContext } from '../components/map/map-context';
import { GameDocumentContext } from '../contexts/game-document';
import { GetResourceValue } from '../utils/game-document/resources';
import { GetImageOverlay } from '../utils/game-document/assets/image-overlay';

export const useGameMapOverlay = () => {
  const map = useContext(MapContext);
  const [gameDocument] = useContext(GameDocumentContext);

  const loadImageOverlay = (location: string) => {
    if (gameDocument.gameDocument) {
      const layers = map.getAllLayers();
      const existingImageLayer = layers.find(
        (layer) => layer.get('id') === 'gpsOverlay'
      );

      if (existingImageLayer) {
        map.removeLayer(existingImageLayer);
      }

      const imageOverlay = GetImageOverlay(
        gameDocument.gameDocument,
        location === 'world-map' ? '' : location
      );

      if (imageOverlay) {
        const imageUrl = GetResourceValue(
          gameDocument.gameDocument,
          imageOverlay.imageResId,
          ''
        );

        const imageLayer = new ImageLayer({
          source: new ImageStatic({
            url: imageUrl,
            imageExtent: imageOverlay.extent
          }),
          opacity: imageOverlay.opacity,
          properties: {
            id: 'gpsOverlay'
          }
        });

        map.setLayers([
          layers[0],
          imageLayer,
          ...layers.slice(1).filter((layer) => layer.get('id') !== 'gpsOverlay')
        ]);
      }
    }
  };

  return { loadImageOverlay };
};
