import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import {
  ErrorsLayout,
  FacilitatorLayout,
  InGameLayout,
  PreGameLayout,
  PublicLayout,
  LeaderboardLayout
} from './layouts';
import { PrivacyPolicyPage, TermsOfUsePage } from './pages/public';
import { PageNotFound } from './pages/errors';
import { SigninOidcPage } from './pages/signin-oidc';
import {
  ChatPage,
  DashboardPage,
  PlayersPage,
  TaskScoringPage,
  TeamsPage,
  PushContentPage,
  Feedback
} from './pages/facilitator';
import LeaderboardPage from './pages/leaderboard/leaderboard';
import { GameLobbyPage, GameMapPage } from './pages/in-game';
import { EnterGameCodePage, JoinGamePage } from './pages/pre-game';
import { FacilitatorLayoutFull } from './layouts/facilitator/facilitator-layout-full';
import { FacilitatorLayoutTab } from './layouts/facilitator/facilitator-layout-tab';
import SplashScreen from './pages/pre-game/splash-screen';
import { PreloadAssetPage } from './pages/pre-game/preload-asset';
import { MapViewPage } from './pages/facilitator/map-view';
import FeedbackLayout from './layouts/feedback/feedback';
import { FeedbackPlayerName } from './pages/feedback/feedback-player-name';
import { FeedbackGames } from './pages/feedback/feedback-games';
import { FeedbackForm } from './pages/feedback/feedback-form';
import { PlayerAssessmentPage } from './pages/assessment/player-assessment';
import { RegisterAssessmentPage } from './components/assessment/register';
import AssessmentLayout from './layouts/assessment';
import { GamePage } from './pages/facilitator/game';
import { Groups } from './pages/facilitator/groups';
import { AllTask } from './pages/facilitator/all-task';

declare global {
  interface Window {
    _env_: any;
  }
}

function App() {
  // prettier-ignore
  const oidcConfig = {
    onSigninCallback: (_user: User | void): void => {
      // HACK!!!!!
      window.history.replaceState({}, document.title, ((_user as User).state as any).returnUrl);
      window.location.reload();
    },
    authority: window?._env_?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY,
    client_id: window?._env_?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
    redirect_uri: window?._env_?.REACT_APP_REDIRECT_URI || process.env.REACT_APP_REDIRECT_URI,
    scope: 'offline_access openid profile', // offline_access scope allow your client to retrieve the refresh_token
    silent_redirect_uri: window?._env_?.REACT_APP_SILENT_REDIRECT_URI || process.env.REACT_APP_SILENT_REDIRECT_URI,
    loadUserInfo: true,
    post_logout_redirect_uri: `${window?._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL}/games`
  };

  // register service worker from public/sw.js for web push notification content (#6608)
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/sw.js')
        .then((registration) => {
          console.log(
            'ServiceWorker registration successful with scope: ',
            registration.scope
          );
        })
        .catch((err) => {
          console.log('ServiceWorker registration failed: ', err);
        });
    });
  }

  return (
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path={'/privacy-policy'} element={<PrivacyPolicyPage />} />
            <Route path={'/terms-of-use'} element={<TermsOfUsePage />} />
          </Route>
          <Route element={<ErrorsLayout />}>
            <Route path={'*'} element={<PageNotFound />} />
          </Route>
          <Route path={'/preload/:gameCode'}>
            <Route element={<PreGameLayout />}>
              <Route index element={<PreloadAssetPage />} />
            </Route>
          </Route>
          <Route path={'/'} element={<EnterGameCodePage />} />

          <Route path={'/games'} element={<EnterGameCodePage />} />
          <Route path={'/games/:gameCode/*'}>
            <Route element={<PreGameLayout />}>
              <Route index element={<JoinGamePage />} />
              <Route path={'splash'} element={<SplashScreen />} />
              <Route
                path={'register-assessment/:teamId?'}
                element={<RegisterAssessmentPage />}
              />
            </Route>
            <Route element={<InGameLayout />}>
              <Route path={'lobby'} element={<GameLobbyPage />} />
              <Route path={'map'} element={<GameMapPage />} />
            </Route>
            <Route element={<AssessmentLayout />}>
              <Route
                index
                path={'assessment/:page?'}
                element={<PlayerAssessmentPage />}
              />
            </Route>
            <Route path={'leaderboard'} element={<LeaderboardLayout />}>
              <Route index element={<LeaderboardPage />} />
            </Route>
            <Route path={'facilitator'} element={<FacilitatorLayout />}>
              <Route element={<FacilitatorLayoutFull />}>
                <Route index element={<DashboardPage />} />
              </Route>

              <Route element={<FacilitatorLayoutTab />}>
                <Route path={'task-scoring'} element={<TaskScoringPage />} />
                <Route path={'players'} element={<PlayersPage />} />
                <Route path={'teams'} element={<TeamsPage />} />
                <Route path={'chat'} element={<ChatPage />} />
                <Route path={'map-view'} element={<MapViewPage />} />
                <Route path={'push-content'} element={<PushContentPage />} />
                <Route path={'all-tasks'} element={<AllTask />} />
                <Route path={'game'} element={<GamePage />} />
                <Route path={'feedback'} element={<Feedback />} />
                <Route path={'groups'} element={<Groups />} />
              </Route>
            </Route>
          </Route>
          <Route path={'/signin-oidc'} element={<SigninOidcPage />} />
          <Route
            path={'/feedbacks/:feedbackCode/*'}
            element={<FeedbackLayout />}>
            <Route index element={<FeedbackPlayerName />} />
            <Route path={'games'} element={<FeedbackGames />} />
            <Route path={'form'} element={<FeedbackForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

/*
 * /privacy-policy
 * /terms-of-use
 * /games
 * /games/:gameCode
 * /games/:gameCode/lobby
 * /games/:gameCode/map
 * /games/:gameCode/facilitator
 * /games/:gameCode/facilitator/example-page
 * /*
 */

export default App;
