import React, { Fragment, JSX, useContext } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { CheckboxOption } from '../../types/game-document/entities/task-content';
import { AnswerStatus } from '../questionnaire';
import { PlayerContext } from '../../contexts/player';
import { GameDocumentContext } from '../../contexts/game-document';
import { GetTaskById } from '../../utils/game-document/assets';

type AnswerOptionProps = {
  group?: string;
  checkboxOptions?: CheckboxOption[];
  taskId?: string;
  formId?: string;
  isDisabled?: boolean;
  isReadonly?: boolean;
  onChange?: (index: number, value: boolean) => void;
};

const AnswerCheckbox = ({
  group,
  checkboxOptions,
  taskId = '',
  formId = '',
  isDisabled = false,
  isReadonly = false,
  onChange = () => {}
}: AnswerOptionProps) => {
  const [player] = useContext(PlayerContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const onCheckboxChangeHandler = (index: number, value: boolean) => {
    onChange(index, value);
  };

  const getPlayerAnswer = (answerResId: string) => {
    const playerTaskContentFormAnswer =
      player.playerState?.tasks?.find((x) => x.id === taskId)
        ?.taskContentFormAnswers ?? [];

    const playerAnswer = playerTaskContentFormAnswer
      ?.find((x) => x.formId === formId)
      ?.answers?.find((x) => x.answerResId === answerResId);

    return playerAnswer;
  };

  const checkCheckboxCorrectAnswer = (
    answerResId: string
  ): boolean | undefined => {
    if (taskId !== '' && formId !== '') {
      const task = GetTaskById(gameDocument.gameDocument, taskId);
      const taskContentAnswers = gameDocument.gameDocument?.assets?.taskContents
        ?.find((i) => i.id === task?.taskContentId)
        ?.forms?.find((i) => i.id === formId)?.answers;
      const taskContentAnswer = taskContentAnswers?.find(
        (i) => i.answerResId === answerResId
      );
      const playerAnswer = getPlayerAnswer(answerResId);
      if (playerAnswer) {
        return playerAnswer.value === taskContentAnswer?.isChecked;
      }
    }
    return;
  };

  const renderBorder = (option: CheckboxOption): string => {
    const playerAnswer = getPlayerAnswer(option.answerResId);
    if (taskId !== '' && formId !== '' && playerAnswer) {
      const isCorrectCheckboxAnswer = checkCheckboxCorrectAnswer(
        option.answerResId
      );
      return isCorrectCheckboxAnswer === undefined
        ? 'border-dark'
        : isCorrectCheckboxAnswer
          ? 'border-success'
          : 'border-danger';
    }
    return 'border-dark';
  };

  const renderAnswerStatus = (option: CheckboxOption): JSX.Element => {
    if (taskId !== '' && formId !== '') {
      const playerAnswer = getPlayerAnswer(option.answerResId);
      return playerAnswer ? (
        <AnswerStatus
          isCorrectAnswer={checkCheckboxCorrectAnswer(option.answerResId)}
        />
      ) : (
        <Fragment />
      );
    }
    return <Fragment />;
  };

  return (
    <div id={'answer-checkbox'}>
      {checkboxOptions &&
        checkboxOptions.map((option: CheckboxOption, index: number) => (
          <div
            className={`d-flex flex-row justify-content-center align-items-center card border border-2 w-100 my-2 ${renderBorder(option)}`}
            key={index}>
            <div className={'card-body flex-grow-1'}>
              <Checkbox
                id={`checkbox-option-${index}`}
                name={group}
                className={'input-checkbox'}
                size={'large'}
                label={option?.description}
                checked={option?.isChecked}
                disabled={isDisabled || isReadonly}
                onChange={(e) =>
                  onCheckboxChangeHandler(index, e.target.value as boolean)
                }
              />
            </div>
            {renderAnswerStatus(option)}
          </div>
        ))}
    </div>
  );
};

export default AnswerCheckbox;
