import { Button } from '@progress/kendo-react-buttons';
import { JSX, useContext } from 'react';
import {
  AchievementContent,
  AchievementContext
} from '../../contexts/achievement';
import AchievementDialog from './achievement-dialog';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';

type Props = {};

const NotificationDialog = (props: Props) => {
  const [achievement, setAchievement] = useContext(AchievementContext);
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);

  const handleOnClose = (data: AchievementContent, index: number): void => {
    achievement.content[index].isHide = true;
    setAchievement({ ...achievement });

    if (data && data.onCloseCallback) data.onCloseCallback();
  };

  return (
    <>
      {achievement.content.map<JSX.Element>(
        (item: AchievementContent, index: number) =>
          !item.isHide ? (
            <AchievementDialog
              key={index}
              imageUrl={item.imageUrl!}
              priorityQueueNumber={item.priorityQueueNumber}
              onMaskClick={() => handleOnClose(item, index)!}>
              {item.message}
              <div>
                <Button
                  fillMode={'outline'}
                  themeColor={'light'}
                  onClick={() => handleOnClose(item, index)!}>
                  {generateTitleById(
                    '48bc820b-40e8-4624-8d46-b68e16013be6',
                    gameDocument,
                    displayLanguageContext.displayLanguageSelected.resources!,
                    'game'
                  ) || 'Close'}
                </Button>
              </div>
            </AchievementDialog>
          ) : (
            <></>
          )
      )}
    </>
  );
};

export default NotificationDialog;
