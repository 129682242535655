import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { GameAlgorithm } from '../game-algorithm';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  giveItem as playerGiveItem,
  giveScore as playerGiveScore,
  giveTitle as playerGiveTitle,
  navigateToWorldMap as playerNavigateToWorldMap,
  navigateToZone as playerNavigateToZone,
  showArea as playerShowArea,
  showTask as playerShowTask,
  openTask as playerOpenTask,
  pauseTimer as playerPauseTimer,
  startTimer as playerStartTimer,
  KickPlayerAsync,
  PostChatFromFacilitator,
  ChatFromFacilitator
} from '../../services/players';
import {
  giveItem as teamGiveItem,
  giveScore as teamGiveScore,
  giveTitle as teamGiveTitle,
  navigateToWorldMap as teamNavigateToWorldMap,
  navigateToZone as teamNavigateToZone,
  showArea as teamShowArea,
  openTask as teamOpenTask,
  showTask as teamShowTask,
  pauseTimer as teamPauseTimer,
  startTimer as teamStartTimer,
  LockAsync,
  DisableTeamChatAsync,
  sendFacilitatorTeamChat
} from '../../services/teams';

import {
  giveItem as groupGiveItem,
  giveScore as groupGiveScore,
  giveTitle as groupGiveTitle,
  navigateToWorldMap as groupNavigateToWorldMap,
  navigateToZone as groupNavigateToZone,
  showArea as groupShowArea,
  openTask as groupOpenTask,
  showTask as groupShowTask,
  pauseTimer as groupPauseTimer,
  startTimer as groupStartTimer
} from '../../services/groups';
import { TeamResponse } from '../../types/responses/team-response';
import { GroupResponse } from '../../types/responses/group-response';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { PlayerStateDashboard } from './state-visualisation/player-state-dashboard';
import { PlayerStateFull, TeamStateFull } from '../../types/state';
import { TeamStateDashboard } from './state-visualisation/team-state-dashboard';
import { FacilitatorAndPlayerChat } from '../../pages/facilitator/chat/facilitator-player-chat';
import { useAuth } from 'react-oidc-context';
import { PlayerResponse } from '../../types/responses/player-response';
import Popup from '../popup-window';
import { FacilitatorTeamChat } from '../../pages/facilitator/chat/team-chat';
import ComboboxInput from '../forms/combobox-input';

interface ListProps {
  isPlayer: boolean;
  isGroup?: boolean;
  groupCode?: string;
  groupList?: GroupResponse[];
  gameCode: string;
  playerOrTeamCode: string | number;
  teamCode?: string;
  state?: any;
  player?: PlayerResponse;
  nameState?: string;
  teamList?: TeamResponse[];
  handleChangeTeam?: (team: TeamResponse) => void;
  handleChangeGroup?: (group: GroupResponse) => void;
  selectedTeam?: TeamResponse;
  selectedGroup?: GroupResponse;
  handleAddPlayerToTeam?: () => void;
  handleRemovePlayerFromTeam?: () => void;
  handleAddTeamToGroup?: () => void;
  handleRemoveTeamFromGroup?: () => void;
  handleRemoveGroup?: () => void;
  isLocked?: boolean;
  isKicked?: boolean;
  isDisabledChat?: boolean;
  isDisableConfirm?: boolean;
  onLockTeam?: (teamcode: string, isLocked: boolean) => void;
  onDisabledChatTeam?: (teamcode: string, isDisabledChat: boolean) => void;
}
interface AlgorithmState {
  operation?: string;
  argument?: string | string[];
  quantity?: number;
}
export const FacilitatorState = ({
  isPlayer = true,
  isGroup = false,
  groupCode,
  groupList = [],
  state,
  player,
  nameState,
  gameCode,
  playerOrTeamCode,
  teamCode,
  teamList = [],
  handleChangeTeam = () => {},
  handleChangeGroup = () => {},
  handleAddPlayerToTeam = () => {},
  handleRemovePlayerFromTeam = () => {},
  handleAddTeamToGroup = () => {},
  handleRemoveTeamFromGroup = () => {},
  handleRemoveGroup = () => {},
  selectedTeam = {},
  selectedGroup = {},
  isLocked,
  isKicked,
  isDisabledChat,
  isDisableConfirm = false,
  onLockTeam,
  onDisabledChatTeam
}: ListProps) => {
  const [algorithmState, setAlgorithmState] = useState<AlgorithmState>({});
  const [isLockTeam, setIsLockTeam] = useState<boolean>(isLocked!);
  const [isDisabledChatTeam, setIsDisabledChatTeam] = useState<boolean>(
    isDisabledChat ? isDisabledChat : false
  );
  const [isPlayerKicked, setIsPlayerKicked] = useState<boolean>(isKicked!);
  const [notification, setNotification] = useContext(NotificationContext);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [showChatTeam, setShowChatTeam] = useState<boolean>(false);
  const auth = useAuth();
  const userId = parseInt(auth.user?.profile.sub!);
  const userName = auth.user?.profile.name!;

  const onChangeOperationHandler = (operation?: string) => {
    setAlgorithmState({ ...algorithmState, operation: operation });
  };

  const onChangeArgumentHandler = (selectedArguments?: string | string[]) => {
    setAlgorithmState({ ...algorithmState, argument: selectedArguments });
  };

  const onChangeQuantityHandler = (quantity?: number) => {
    setAlgorithmState({ ...algorithmState, quantity: quantity });
  };

  const responseTeamNotification = (
    message: string,
    isError: boolean = false
  ) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: `${isError ? 'k-button--gradient-error' : 'k-button--gradient'}`
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onConfirmHandler = async () => {
    try {
      if (isPlayer) {
        let result = await confirmPlayerHandler();

        if (result) {
          responseTeamNotification('Successfully sent the command');
        } else {
          responseTeamNotification('Failed sent the command', true);
        }
      } else if (!isPlayer && !isGroup) {
        try {
          await confirmTeamHandler();
          responseTeamNotification('Successfully sent the command');
        } catch (error) {
          responseTeamNotification('Failed sent the command', true);
        }
      } else {
        try {
          await confirmGroupHandler();

          responseTeamNotification('Successfully sent the command');
        } catch (error) {
          responseTeamNotification('Failed sent the command', true);
        }
      }
    } catch (error) {}
  };

  const confirmPlayerHandler = async () => {
    const code = playerOrTeamCode as string;

    switch (algorithmState?.operation) {
      case 'navigateToWorldMap':
        return await playerNavigateToWorldMap(gameCode!, code!);
      case 'navigateToZone':
        return await playerNavigateToZone(
          gameCode!,
          code!,
          algorithmState?.argument! as string
        );
      case 'openTask':
        return await playerOpenTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string
        );
      case 'showTask':
        return await playerShowTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'hideTask':
        return await playerShowTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'showArea':
        return await playerShowArea(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'hideArea':
        return await playerShowArea(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'startTimer':
        return await playerStartTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'stopTimer':
        return await playerStartTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'pauseTimer':
        return await playerPauseTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'resumeTimer':
        return await playerPauseTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'giveItem':
        return await playerGiveItem(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          algorithmState?.quantity!,
          true
        );
      case 'removeItem':
        return await playerGiveItem(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          algorithmState?.quantity! * -1,
          false
        );
      case 'giveScore':
        return await playerGiveScore(
          gameCode!,
          code!,
          algorithmState?.quantity!,
          true
        );
      case 'removeScore':
        return await playerGiveScore(
          gameCode!,
          code!,
          algorithmState?.quantity! * -1,
          false
        );
      case 'giveTitle':
        return await playerGiveTitle(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'removeTitle':
        return await playerGiveTitle(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      default:
        break;
    }
  };

  const confirmTeamHandler = async () => {
    const code = playerOrTeamCode as string;

    switch (algorithmState?.operation) {
      case 'navigateToWorldMap':
        return await teamNavigateToWorldMap(gameCode!, code!);
      case 'navigateToZone':
        return await teamNavigateToZone(
          gameCode!,
          code!,
          algorithmState?.argument! as string
        );
      case 'openTask':
        return await teamOpenTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string
        );
      case 'showTask':
        return await teamShowTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'hideTask':
        return await teamShowTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'showArea':
        return await teamShowArea(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'hideArea':
        return await teamShowArea(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'startTimer':
        return await teamStartTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'stopTimer':
        return await teamStartTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'pauseTimer':
        return await teamPauseTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'resumeTimer':
        return await teamPauseTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'giveItem':
        return await teamGiveItem(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          algorithmState?.quantity!,
          true
        );
      case 'removeItem':
        return await teamGiveItem(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          algorithmState?.quantity! * -1,
          false
        );
      case 'giveScore':
        return await teamGiveScore(
          gameCode!,
          code!,
          algorithmState?.quantity!,
          true
        );
      case 'removeScore':
        return await teamGiveScore(
          gameCode!,
          code!,
          algorithmState?.quantity! * -1,
          false
        );
      case 'giveTitle':
        return await teamGiveTitle(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'removeTitle':
        return await teamGiveTitle(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      default:
        break;
    }
  };

  const confirmGroupHandler = async () => {
    const code = groupCode as string;

    switch (algorithmState?.operation) {
      case 'navigateToWorldMap':
        return await groupNavigateToWorldMap(gameCode!, code!);
      case 'navigateToZone':
        return await groupNavigateToZone(
          gameCode!,
          code!,
          algorithmState?.argument! as string
        );
      case 'openTask':
        return await groupOpenTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string
        );
      case 'showTask':
        return await groupShowTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'hideTask':
        return await groupShowTask(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'showArea':
        return await groupShowArea(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'hideArea':
        return await groupShowArea(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'startTimer':
        return await groupStartTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'stopTimer':
        return await groupStartTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'pauseTimer':
        return await groupPauseTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'resumeTimer':
        return await groupPauseTimer(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      case 'giveItem':
        return await groupGiveItem(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          algorithmState?.quantity!,
          true
        );
      case 'removeItem':
        return await groupGiveItem(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          algorithmState?.quantity! * -1,
          false
        );
      case 'giveScore':
        return await groupGiveScore(
          gameCode!,
          code!,
          algorithmState?.quantity!,
          true
        );
      case 'removeScore':
        return await groupGiveScore(
          gameCode!,
          code!,
          algorithmState?.quantity! * -1,
          false
        );
      case 'giveTitle':
        return await groupGiveTitle(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          true
        );
      case 'removeTitle':
        return await groupGiveTitle(
          gameCode!,
          code!,
          algorithmState?.argument! as string,
          false
        );
      default:
        break;
    }
  };

  const LockTeamAsync = async (isLocked: boolean) => {
    try {
      await LockAsync(gameCode!, playerOrTeamCode as string, isLocked);
    } catch (error) {
      console.error(error);
    }
  };

  const LockHandler = () => {
    let tempIsLockTeam = isLockTeam;
    try {
      LockTeamAsync(!tempIsLockTeam);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLockTeam(!isLockTeam);

      if (onLockTeam) {
        onLockTeam(playerOrTeamCode as string, !tempIsLockTeam);
      }
    }
  };

  const DisableChatAsync = async (isDisabledChatTeam: boolean) => {
    try {
      await DisableTeamChatAsync(
        gameCode!,
        playerOrTeamCode as string,
        isDisabledChatTeam
      );
    } catch (error) {
      console.error(error);
    }
  };

  const ChatHandler = () => {
    let tempIDisabledChatTeam = isDisabledChatTeam;
    try {
      DisableChatAsync(!isDisabledChatTeam);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisabledChatTeam(!isDisabledChatTeam);
      if (onDisabledChatTeam) {
        onDisabledChatTeam(playerOrTeamCode as string, !tempIDisabledChatTeam);
      }
    }
  };

  // this handler is for the player kicked out of the game
  // temporarily not used due to task 6422, maybe in the future it will be used again
  const KickHandler = () => {
    try {
      KickPlayerAsync(gameCode, playerOrTeamCode as string);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPlayerKicked(!isPlayerKicked);
    }
  };

  const onSendMessageHandler = async (message: string) => {
    let payload: ChatFromFacilitator = {
      facilitatorName: userName,
      facilitatorUserProfileId: userId,
      toPlayerCode: (player as PlayerResponse).code!,
      message: message
    };

    await PostChatFromFacilitator(gameCode!, payload);
  };

  const onSendMessageTeamHandler = async (message: string) => {
    await sendFacilitatorTeamChat(
      gameCode!,
      selectedTeam?.teamCode,
      userId!,
      userName!,
      message!
    );
  };

  useEffect(() => {
    setIsLockTeam(isLocked!);
    setIsDisabledChatTeam(isDisabledChat!);
  }, [isLocked, nameState, isDisabledChat]);

  return (
    <Row className={'d-flex flex-column gap-4'}>
      {/*Group don't need this*/}
      {!isGroup && (
        <Col className={'col-12'}>
          <div className={'text-break mt-4 fs-6 mt-5'}>
            <div>
              {state ? (
                isPlayer ? (
                  <PlayerStateDashboard
                    state={state as PlayerStateFull}
                    group={groupList?.find(
                      (x) =>
                        x.code ===
                        teamList?.find((x) => x.teamCode === teamCode)
                          ?.groupCode
                    )}
                    team={teamList?.find((x) => x.teamCode === teamCode)}
                  />
                ) : state && !isGroup ? (
                  <TeamStateDashboard
                    state={state as TeamStateFull}
                    group={groupList?.find(
                      (x) => x.code === selectedGroup.code
                    )}
                  />
                ) : !isGroup ? (
                  <pre>{`Creating ${
                    isPlayer ? 'Player' : 'Team'
                  } State, Please wait ... `}</pre>
                ) : (
                  ''
                )
              ) : (
                <pre>{`Creating ${
                  isPlayer ? 'Player' : 'Team'
                } State, Please wait ... `}</pre>
              )}
            </div>
          </div>
        </Col>
      )}

      {/*Group don't need this*/}
      {!isGroup && (
        <Col className={'col-12'}>
          <div>
            <hr />
            {state && (
              <div className={'mb-2'}>
                {!isGroup ? (
                  <label className={'text-secondary'}>
                    {isPlayer ? 'Add player to a team' : ''}
                  </label>
                ) : (
                  <b>Group state</b>
                )}
              </div>
            )}
            {/* teams component */}
            {!isGroup && state && !isPlayer && (
              <>
                <div className={'d-flex align-items-center gap-2'}>
                  <ComboboxInput
                    data={groupList}
                    placeholder={'Select a group'}
                    textField={'name'}
                    dataItemKey={'name'}
                    className={'border w-20'}
                    onChange={(e) => {
                      handleChangeGroup(e.target.value);
                    }}
                    value={
                      groupCode !== ''
                        ? groupList?.find((x) => x.code === groupCode)
                        : null
                    }
                  />
                  <Button
                    onClick={handleAddTeamToGroup}
                    fillMode={'outline'}
                    themeColor={'primary'}
                    disabled={isEmpty(groupCode)}>
                    Add to group
                  </Button>
                  <Button
                    themeColor={'primary'}
                    fillMode={'outline'}
                    onClick={handleRemoveTeamFromGroup}
                    disabled={
                      !isEmpty(groupCode) || !isEmpty(selectedGroup.code)
                        ? false
                        : true
                    }>
                    Remove from group
                  </Button>
                </div>
              </>
            )}
            {/* players component */}
            {playerOrTeamCode && state && isPlayer && (
              <>
                <div className={'d-flex align-items-center'}>
                  <ComboboxInput
                    data={teamList}
                    textField={'name'}
                    dataItemKey={'name'}
                    className={'border border-dark w-20'}
                    onChange={(e) => {
                      handleChangeTeam(e.target.value);
                    }}
                    value={
                      teamCode !== ''
                        ? teamList?.find((x) => x.teamCode === teamCode)
                        : null
                    }
                  />
                  <Button
                    fillMode={'outline'}
                    className={'ml-2 btn-icon-center'}
                    onClick={handleAddPlayerToTeam}
                    disabled={!isEmpty(teamCode) ? false : true}>
                    Add to team
                  </Button>
                  <Button
                    fillMode={'outline'}
                    className={' ml-2 btn-icon-center'}
                    onClick={handleRemovePlayerFromTeam}
                    disabled={!isEmpty(teamCode) ? false : true}>
                    Remove from team
                  </Button>
                </div>
                {!isPlayer && (
                  <Button
                    themeColor={`${isLockTeam ? 'info' : 'warning'}`}
                    className={'w-10 ml-0 h-3'}
                    onClick={LockHandler}>
                    {isLockTeam ? 'Unlock Team' : 'Lock Team'}
                  </Button>
                )}
              </>
            )}
          </div>
        </Col>
      )}

      {/* players, teams and groups component */}
      {(state || isGroup) && (
        <Col md={{ span: 12 }} className={'pl-1'}>
          <hr className={'ml-2'} />
          <div>
            <label className={'pl-2 text-secondary'}>Manual Action</label>
            <div
              className={
                'd-flex justify-content-between align-items-center w-100'
              }>
              <div>
                <GameAlgorithm
                  onChangeOperation={onChangeOperationHandler}
                  onChangeArgument={onChangeArgumentHandler}
                  onChangeQuantity={onChangeQuantityHandler}
                  disabled={isDisableConfirm}
                />
              </div>
              <Button
                themeColor={'success'}
                className={'w-8 ml-2'}
                disabled={isDisableConfirm}
                onClick={onConfirmHandler}>
                Confirm
              </Button>
            </div>
          </div>
        </Col>
      )}

      {/* teams component */}
      {!isGroup && state && !isPlayer && (
        <Col className={'col-12'}>
          <hr />
          <div className={'d-flex gap-2'}>
            <Button
              onClick={ChatHandler}
              themeColor={'primary'}
              fillMode={'outline'}>
              {isDisabledChatTeam ? 'Enable chat' : 'Disable chat'}
            </Button>
            <Button
              themeColor={'primary'}
              onClick={() => setShowChatTeam(true)}>
              Chat to team
            </Button>
          </div>
          <hr />
          <div>
            <Button
              onClick={LockHandler}
              themeColor={'error'}
              fillMode={'outline'}>
              <span className={'d-flex align-items-center gap-1'}>
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: '20px'
                  }}>
                  lock
                </span>
                {isLockTeam ? 'Unlock team' : 'Lock team'}
              </span>
            </Button>
          </div>
        </Col>
      )}

      {/* players component */}
      {state && isPlayer && (
        <Col className={'col-12'}>
          <hr />
          <div className={'d-flex flex-row-reverse gap-2'}>
            {/* #6422 currently lock team and disable chat just for teams feature */}
            <Button themeColor={'primary'} onClick={() => setShowChat(true)}>
              Chat
            </Button>
          </div>
        </Col>
      )}

      {/* player component */}
      {showChat && player && (
        <Popup
          className={'box d-flex flex-column gap-3 w-50'}
          handleClose={() => setShowChat(false)}>
          <FacilitatorAndPlayerChat
            item={player as PlayerResponse}
            onSendMessage={onSendMessageHandler}
          />
        </Popup>
      )}

      {/* team chat component */}
      {showChatTeam && (
        <Popup
          className={'box d-flex flex-column gap-3 w-50'}
          handleClose={() => setShowChatTeam(false)}>
          <FacilitatorTeamChat
            code={(selectedTeam as any)?.teamCode}
            item={selectedTeam as any}
            onSendMessage={onSendMessageTeamHandler}
          />
        </Popup>
      )}
    </Row>
  );
};
