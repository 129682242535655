import {
  DisplayLangResource,
  DisplayLanguage
} from '../types/game-document/entities/display-languages';

export const DisplayLangResources: DisplayLangResource[] = [
  {
    //game display language part
    displayId: '001',
    id: 'f7c9251f-9bba-47c8-a554-7ddc95cd0dd4',
    value: 'Preload Assets',
    type: 'text'
  },
  {
    displayId: '002',
    id: 'd705595a-5864-4eb7-aa72-af7b2f2bb19e',
    type: 'text',
    value: 'It will take a few seconds'
  },
  {
    displayId: '003',
    id: '5d7d9dae-f5d4-45ba-993d-22fa61ca16e5',
    type: 'text',
    value: '% Total assets'
  },
  {
    displayId: '004',
    id: '079c039a-8207-4404-8eb6-558cf5d8477b',
    type: 'text',
    value: 'Next'
  },
  {
    displayId: '005',
    id: 'd594b340-e6db-4822-9dfa-6701afbd5677',
    value: 'Language',
    type: 'text'
  },
  {
    displayId: '006',
    id: 'bb8f780c-fbd9-48d5-a725-3efb8dcba95c',
    value: 'Select language',
    type: 'text'
  },
  {
    //this contants has been removed
    displayId: '007',
    id: 'd983e88f-a82c-4961-b69e-da3fb34a9570',
    value: 'Select language for display',
    type: 'text'
  },
  {
    displayId: '008',
    id: '00100845-d632-4d60-9140-46dddb187781',
    value: 'Your player name',
    type: 'text'
  },
  {
    displayId: '009',
    id: '3cf798f7-da4d-47b4-a3d2-713191b0d3cc',
    value:
      'Now, give yourself a fun player name. it doesn\u0027t have to be your real name',
    type: 'text'
  },
  {
    displayId: '010',
    id: 'ff5ffdc0-5b2d-4b40-a1b0-66f3c886df89',
    value: 'Player Name',
    type: 'text'
  },
  {
    displayId: '011',
    id: 'ab253e81-5612-4cc8-93b3-56cb2e3a056b',
    value: 'Your avatar',
    type: 'text'
  },
  {
    displayId: '012',
    id: '4e040b03-759f-49e3-b48a-7d0c0a1914e9',
    value: 'Upload an image of your choice',
    type: 'text'
  },
  {
    displayId: '013',
    id: '750604c5-d26a-4dfb-9db1-25eda7a6d95f',
    value: 'Or choose an avatar from our library',
    type: 'text'
  },
  {
    displayId: '014',
    id: '5f243f13-e05b-448b-8a19-b45e16f9719c',
    value: 'Finish',
    type: 'text'
  },
  {
    displayId: '015',
    id: '670d9f08-d761-4c71-883c-4265648d6a73',
    value: 'Info',
    type: 'text'
  },
  {
    displayId: '016',
    id: '4be208b6-39fc-434d-bae7-e39f6d1ed332',
    value: 'Teams',
    type: 'text'
  },
  {
    displayId: '017',
    id: 'f7a7c135-91e2-433f-a7ed-0b62ff39aba0',
    value: 'Inventory',
    type: 'text'
  },
  {
    displayId: '018',
    id: '832f9ead-d125-4418-aebb-ebdff00a3a8d',
    value: 'Roles',
    type: 'text'
  },
  {
    displayId: '019',
    id: '57f9b876-0dcf-4e59-b240-2dc88aa9390e',
    value: 'Chat',
    type: 'text'
  },
  {
    displayId: '020',
    id: 'e698200c-73e4-41e3-b4b1-fa4b38f4638c',
    value: 'Leaderboard',
    type: 'text'
  },
  {
    displayId: '021',
    id: '444fa3d5-d8b7-4c38-bceb-41eeac0b2c60',
    value: 'Pre-download',
    type: 'text'
  },
  {
    displayId: '022',
    id: 'a1182fb6-bf28-4ce3-899d-e998ba2565f6',
    value: 'Diagnostics',
    type: 'text'
  },
  {
    displayId: '023',
    id: 'e4d66a9d-ed75-443e-82f1-9a8da53325d8',
    value: 'Enter Game',
    type: 'text'
  },
  {
    displayId: '024',
    id: '848ee4b0-e2ce-4974-a17c-d53bf10f6719',
    value: 'Create new team',
    type: 'text'
  },
  {
    displayId: '025',
    id: '5bc0109c-91f4-473e-94e1-99444faeaabf',
    value: 'Team name',
    type: 'text'
  },
  {
    displayId: '026',
    id: '6db126df-9980-4fed-b80a-1d7a29dbb662',
    value: 'Save',
    type: 'text'
  },
  {
    displayId: '027',
    id: '0d09f801-d473-4918-adb3-04752d47664b',
    value: 'Players items',
    type: 'text'
  },
  {
    displayId: '028',
    id: 'c22c93e0-8474-479c-b734-53312614d3c5',
    value: 'Team items',
    type: 'text'
  },
  {
    displayId: '029',
    id: '2f276a31-f0d1-48f7-9ce3-0f51488b35c9',
    value: 'Available items',
    type: 'text'
  },
  {
    displayId: '030',
    id: 'f932f99f-2e5f-4b02-9977-ba9a58764a8e',
    value: 'Player titles',
    type: 'text'
  },
  {
    displayId: '031',
    id: '5c5b9991-689f-459d-99b9-0453d313f8c9',
    value: 'Team titles',
    type: 'text'
  },
  {
    displayId: '032',
    id: 'cf3a5e89-6556-4671-9593-0f930e29c58d',
    value: 'Available titles',
    type: 'text'
  },
  {
    displayId: '033',
    id: 'e20069a1-1a00-4183-b62e-7375d02b4721',
    value: 'Chat with',
    type: 'text'
  },
  {
    displayId: '034',
    id: 'bd2f061c-b665-4fe5-84ce-b986a1008a76',
    value: 'All players',
    type: 'text'
  },
  {
    displayId: '035',
    id: '6a6f31b6-fb91-42f8-acab-f469e0ef1d86',
    value: 'My team',
    type: 'text'
  },
  {
    displayId: '036',
    id: '8fd3fb0f-e237-4717-9272-98a84f4e9af4',
    value: 'My group',
    type: 'text'
  },
  {
    displayId: '037',
    id: 'fe72a8db-b666-4720-b08c-6081605cd24c',
    value: 'Facilitators',
    type: 'text'
  },
  {
    displayId: '038',
    id: '2373ccd3-e73e-403d-bd99-7083f1512a96',
    value: 'Type a message...',
    type: 'text'
  },
  {
    displayId: '039',
    id: '6560df9f-604b-4430-83f4-7b61575e25ba',
    value: 'Score',
    type: 'text'
  },
  {
    displayId: '040',
    id: '24de378f-6ae4-4773-a912-30e5bcaf53aa',
    value: 'Pre-download',
    type: 'text'
  },
  {
    displayId: '041',
    id: '8c7f42ef-4a80-431f-b967-5a6d81b53b15',
    value: 'Download',
    type: 'text'
  },
  {
    displayId: '042',
    id: '5163981d-0d89-4afb-b0d8-f2d0e42de142',
    value: 'Cancel',
    type: 'text'
  },
  {
    displayId: '043',
    id: '3648e23a-3c13-47ed-b89f-15d10681d547',
    value: 'Diagnostics',
    type: 'text'
  },
  {
    displayId: '044',
    id: 'f27a1675-58e4-4e01-93a0-f6a830eba04f',
    value: 'The game has ended',
    type: 'text'
  },
  {
    displayId: '045',
    id: '6e07cb7a-3a2c-484f-9824-15d1db9629d0',
    value: 'You can still access the main menu and chat.',
    type: 'text'
  },
  {
    displayId: '046',
    id: '66883910-7d5d-4858-8ca0-f433072cc57e',
    value: 'Join team',
    type: 'text'
  },
  {
    displayId: '047',
    id: '983f048a-ec82-47fd-9f7e-c59ab3526d5a',
    value: 'Leave team',
    type: 'text'
  },
  {
    displayId: '048',
    id: '49aee67e-8bc4-4680-98f4-03d4636bcc73',
    value: 'Team member',
    type: 'text'
  },
  {
    displayId: '049',
    id: '82f09d89-f2e6-4ec2-ac6a-644b40d503bf',
    value: 'Name',
    type: 'text'
  },
  {
    displayId: '050',
    id: '42b77164-cdf8-4222-94ad-4e5652b6b2f8',
    value: 'No photo submitted. Please try again.',
    type: 'text'
  },
  {
    displayId: '051',
    id: '4981eed4-5e4a-4f90-819e-385c7634a48e',
    value: 'No video submitted. Please try again.',
    type: 'text'
  },
  {
    id: 'be998642-6834-40d7-b457-55acafbe0fc0',
    value: 'Continue',
    type: 'text'
  },
  {
    id: '48bc820b-40e8-4624-8d46-b68e16013be6',
    value: 'Close',
    type: 'text'
  },
  {
    id: '8065dbef-51c3-4e7b-82e8-48ea0bfdf093',
    value: 'Submit answer',
    type: 'text'
  },
  {
    id: 'de8a3cf6-ee45-4549-931b-d3be8c91ba4c',
    value: 'Time Remaining',
    type: 'text'
  },
  {
    id: '80884474-f011-402e-b4ea-6aa791457ed9',
    value: 'Correct',
    type: 'text'
  },
  {
    id: '3a0d67d6-8374-43ae-a050-2c256a3bece4',
    value: 'Incorrect',
    type: 'text'
  },
  {
    id: '326560e1-3c4b-4e0b-a31e-8fecfc533fd7',
    value: 'Answer submitted',
    type: 'text'
  },
  {
    id: '567963b7-47c0-40e9-8659-2e43a5a5befb',
    value: 'Preview',
    type: 'text'
  },
  {
    id: 'b6d7d97b-37bd-4ef9-aa9e-0e02a404a375',
    value: 'Add from files',
    type: 'text'
  },
  {
    id: '51689c93-1e57-4c72-9308-5deab301614a',
    value: 'Take from camera',
    type: 'text'
  },
  {
    id: '59473aee-f6c3-4657-aa6e-f72a9726e4dc',
    value: 'Turn off camera',
    type: 'text'
  },
  {
    id: '7b63892e-236c-4b6a-bfec-d5d3d66794af',
    value: 'Take picture',
    type: 'text'
  },
  {
    id: '71a2dffb-7ae7-422f-8423-93b0d4f15a4a',
    value: 'Uploading...',
    type: 'text'
  },
  {
    id: '3d6b40c6-d321-441b-8b8b-5982da856e9a',
    value: 'Recording...',
    type: 'text'
  },
  {
    id: 'e1527e98-9fba-4a13-b082-7c510d10a4e0',
    value: 'Submit video',
    type: 'text'
  },
  {
    id: '9ff35578-4946-4900-a4a3-e76f776ab4ca',
    value: 'Invalid File Type: Please upload video files only.',
    type: 'text'
  },
  {
    id: '4af50ef9-db25-48cf-9fa2-2a5ad295d6cf',
    value: 'Invalid File Type: Please upload image files only.',
    type: 'text'
  },
  {
    id: 'f46afd85-3307-480b-97ed-03a12645c906',
    value: 'Incorrect % points awarded.',
    type: 'text'
  },
  {
    id: 'cea1d18b-4808-4c1f-8d46-88edf44ba525',
    value: 'points awarded for your correct answer!',
    type: 'text'
  },
  {
    id: '32681cc9-9ee0-4d19-81fa-7a2b6e69a362',
    value: 'Your answer has been submitted to the Facilitator for review',
    type: 'text'
  },
  {
    id: '88a6f4c3-09f5-4a93-89de-62f1d6c13938',
    value: "Congratulations. You've been awarded % points.",
    type: 'text'
  },
  {
    id: '4ab8e32c-69bc-49f5-8364-1b4953c37c59',
    value: 'points deducted for your wrong answer!',
    type: 'text'
  },
  {
    id: 'c0acec61-3782-4e51-bac5-3cfa930c2586',
    value: 'Re-submit',
    type: 'text'
  },
  {
    id: '298f372e-f97b-42c6-b1a7-a0bf7866ec7b',
    value: 'Try again - % attempt(s) remaining',
    type: 'text'
  },
  {
    id: '632889a8-5e96-457f-b587-6e98df891c19',
    value: 'Warning',
    type: 'text'
  },
  {
    id: '0c38ed7b-a7b0-4091-bc8b-af7271652d2e',
    value: 'This is a team game. You must be part of a team to join the game',
    type: 'text'
  },
  {
    id: 'fb5a1ca5-069e-4dda-aa26-a414100396da',
    value: 'Select a team',
    type: 'text'
  },
  //game display language part end
  //feedback display language part
  {
    displayId: 'fb-1',
    id: '3ed6fe99-9545-44b2-9800-52495d998967',
    value: 'Name',
    type: 'text'
  },
  {
    displayId: 'fb-2',
    id: '8f2695c9-3074-48bf-b68a-76d5beaffa44',
    value: 'Anonymous',
    type: 'text'
  },
  {
    displayId: 'fb-3',
    id: 'd9e11c05-454f-44c0-bbc9-5de7dedf8f69',
    value: 'Your Games',
    type: 'text'
  },
  {
    displayId: 'fb-4',
    id: '84bccd9b-ed5d-4daa-b564-1aeb96c0929a',
    value: 'Continue',
    type: 'text'
  },
  {
    displayId: 'fb-5',
    id: '5463fd5e-5a92-4e61-9cd7-162fb43668fe',
    value: 'Question',
    type: 'text'
  },
  {
    displayId: 'fb-6',
    id: '9218c923-0be1-47f8-ae56-565694b5de33',
    value: 'Type your answer here…',
    type: 'text'
  },
  {
    displayId: 'fb-7',
    id: '03e6aadb-3a09-4d5e-a27a-dac966707a0f',
    value: 'Skip',
    type: 'text'
  },
  {
    displayId: 'fb-8',
    id: '9a5c9d53-6aae-4be4-b556-213fee60eeb7',
    value: 'Submit Answer',
    type: 'text'
  },
  {
    displayId: 'fb-9',
    id: '7a4ce6eb-62e6-4ac4-a53f-2df466858a52',
    value: 'Thank you for your time',
    type: 'text'
  },
  {
    displayId: 'fb-10',
    id: '05635089-36d4-4205-967f-8d60e7311ca9',
    value:
      'We value your feedback. The survey is complete. You can now close this page.',
    type: 'text'
  },
  {
    displayId: 'fb-11',
    id: '77284806-5846-4da9-a92f-1aee27ad6943',
    value: 'Please select the activity to provide feedback on.',
    type: 'text'
  },
  {
    displayId: 'fb-12',
    id: '714c5007-ce05-477e-8dc2-8f9502eb4c45',
    value: 'Previous',
    type: 'text'
  },
  {
    displayId: 'fb-13',
    id: '6101dd6b-0e30-4518-b9d9-37a4173c1031',
    value: 'Next',
    type: 'text'
  },
  //feedback display language part end
  //assessment display language part
  {
    displayId: 'am-1',
    id: 'fe3c349a-a3a0-4ac9-8396-8b4a2d487e41',
    value: 'Player name',
    type: 'text'
  },
  {
    displayId: 'am-2',
    id: '31c384a2-7830-4a41-ad03-8306a14b7335',
    value: 'Name',
    type: 'text'
  },
  {
    displayId: 'am-3',
    id: '89572b6f-f14d-4cc4-bac0-c42b0b3617ff',
    value: 'Team',
    type: 'text'
  },
  {
    displayId: 'am-4',
    id: '793cc29d-e14c-4248-af9a-57e809631210',
    value: 'Language',
    type: 'text'
  },
  {
    displayId: 'am-5',
    id: '89bdf773-24c6-4d57-87ef-1df6e42dd064',
    value: 'Anonymous',
    type: 'text'
  },
  {
    displayId: 'am-6',
    id: 'a6855a76-d452-4243-bb4b-0b6016ba1a99',
    value: 'The assessment will begin shortly',
    type: 'text'
  },
  {
    displayId: 'am-7',
    id: 'f4bd484c-991d-4470-bc89-de6b7116bde9',
    value: 'Competencies',
    type: 'text'
  },
  {
    displayId: 'am-8',
    id: 'c082579f-1822-411e-92db-5f11e10147c3',
    value: 'Action Plan',
    type: 'text'
  },
  {
    displayId: 'am-9',
    id: '272ee4de-783f-4c53-be6e-65eb18eee2ad',
    value: 'Rank the following statements',
    type: 'text'
  },
  {
    displayId: 'am-10',
    id: 'a1445688-fa0b-4680-b0ce-4119d3892e49',
    value: '1 being strongly disagree and 10 being strongly agree.',
    type: 'text'
  },
  {
    displayId: 'am-11',
    id: '43507c9f-5dc4-4ef7-997c-f6bb2202bfb5',
    value: 'Next',
    type: 'text'
  },
  {
    displayId: 'am-12',
    id: '927bb333-71fa-407c-b466-5320064cb489',
    value: 'Thanks for your submission',
    type: 'text'
  },
  {
    displayId: 'am-13',
    id: 'b4f56ee6-99a9-4a90-922c-7b8bedf6fa5e',
    value: 'The assessment will continue shortly',
    type: 'text'
  },
  {
    displayId: 'am-14',
    id: '277ea8ef-3999-42f9-8d91-4c9de15096c4',
    value: 'Actions',
    type: 'text'
  },
  {
    displayId: 'am-15',
    id: 'dbadbbfd-0dd7-4bfb-a7a2-daa93937ba95',
    value: 'Purpose statement',
    type: 'text'
  },
  {
    displayId: 'am-16',
    id: 'fb8d44fa-12f9-482d-a726-f4900d106b03',
    value: 'Summary',
    type: 'text'
  },
  {
    displayId: 'am-17',
    id: '4f7e2692-0416-4547-882c-ce049bb44132',
    value: 'Add in your suggestion for actions the group could take.',
    type: 'text'
  },
  {
    displayId: 'am-18',
    id: '68fe4076-4770-4624-8b14-fda0e1797cda',
    value: 'Add',
    type: 'text'
  },
  {
    displayId: 'am-19',
    id: '013a790c-683b-4d9d-a54b-b5f41a642b89',
    value: 'Action',
    type: 'text'
  },
  {
    displayId: 'am-20',
    id: '419aec07-4626-4068-a964-b06444c20d5e',
    value: 'Vote',
    type: 'text'
  },
  {
    displayId: 'am-21',
    id: '8359bac9-81c4-4973-9368-6d0e36b6ed46',
    value:
      'You have 10 votes to allocate. The more you like an idea, the more votes you should give it.',
    type: 'text'
  },
  {
    displayId: 'am-22',
    id: '7186690f-ff05-4378-99a3-dc0d6d9edba4',
    value: 'Votes remaining',
    type: 'text'
  },
  {
    displayId: 'am-23',
    id: '2f9df4c1-568c-4eac-a7aa-4d423c051723',
    value: 'Review',
    type: 'text'
  },
  {
    displayId: 'am-24',
    id: '3f95d8ff-c918-4a71-a725-b35807e0a1a5',
    value: 'Status',
    type: 'text'
  },
  {
    displayId: 'am-25',
    id: 'fd8be6bf-8aa0-4cc7-8b26-cd835ca8691c',
    value: 'Date',
    type: 'text'
  },
  {
    displayId: 'am-26',
    id: '8eceb0d5-ee97-4fad-b694-23513a9b825a',
    value: 'Person in Charge',
    type: 'text'
  },
  {
    displayId: 'am-27',
    id: '806a090c-2ded-4c0d-802a-47b12a1a4ffb',
    value: 'Note',
    type: 'text'
  },
  {
    displayId: 'am-28',
    id: '87e9994f-235b-4198-86e2-be2683bc2086',
    value: 'Measurables',
    type: 'text'
  },
  {
    displayId: 'am-29',
    id: 'ee8536b9-0b87-4c92-b321-91aebffebd64',
    value:
      'How will you know that these actions are helping to achieve the purpose?',
    type: 'text'
  },
  {
    displayId: 'am-30',
    id: '4d60fe43-6260-4627-9837-bb5715b0832d',
    value: 'What does success look like?',
    type: 'text'
  },
  {
    displayId: 'am-31',
    id: '19848afc-259e-49ec-bd1a-b5789356e999',
    value: 'Comment here…',
    type: 'text'
  },
  {
    displayId: 'am-32',
    id: 'ecb9b044-f0da-4334-b1cf-98d1fd8701ff',
    value: 'Finish',
    type: 'text'
  },
  {
    displayId: 'am-33',
    id: '7ee661fa-3410-40d6-84cf-abd3bb445705',
    value: 'Are you sure you want to proceed?',
    type: 'text'
  },
  {
    displayId: 'am-34',
    id: 'cdd979af-adbb-47e7-8284-68af16f8414a',
    value: "You can't go back if you proceed.",
    type: 'text'
  },
  {
    displayId: 'am-35',
    id: 'd0909540-655f-4e01-b853-0f1db881bd1a',
    value: 'Participants',
    type: 'text'
  },
  {
    displayId: 'am-36',
    id: '220ceb83-01d8-4fd5-8dc6-ba41017ff822',
    value:
      'The bar chart shows the spread of ratings submitted by participants. Where no bar is shown, it indicates that participant responses were aligned.',
    type: 'text'
  },
  {
    displayId: 'am-37',
    id: '7a0cd700-6c03-4b3a-acad-cb80cecb0ef1',
    value: 'Approved',
    type: 'text'
  },
  {
    displayId: 'am-38',
    id: '45f53973-be97-4cb8-b159-3fa0f43c4b18',
    value: 'Declined',
    type: 'text'
  },
  {
    displayId: 'am-39',
    id: '484a765c-43bf-43bb-820e-5cf41f668473',
    value: 'Thanks for taking part',
    type: 'text'
  },
  {
    displayId: 'am-40',
    id: '8d991c05-f6b5-4f90-9d1b-35485bbfebf3',
    value: 'The assessment has now ended',
    type: 'text'
  },
  {
    displayId: 'am-41',
    id: '95065f71-e1f5-4375-8663-89e95c331d95',
    value: 'Download report',
    type: 'text'
  },
  {
    displayId: 'am-42',
    id: 'f35f1243-f9e5-49b9-8c33-a07dc8b001a5',
    value: 'Assessment Summary',
    type: 'text'
  },
  {
    displayId: 'am-43',
    id: 'd4ec8848-3794-45e7-b70b-b8705a48cc82',
    value: 'Event Name',
    type: 'text'
  },
  {
    displayId: 'am-44',
    id: 'd1b90aaa-d71d-40e9-81a4-716e2630ea5d',
    value: 'Assessment Name',
    type: 'text'
  },
  {
    displayId: 'am-45',
    id: '68dc720a-e8df-4fc4-bb9b-acc9facf6636',
    value: 'Submitted Survey',
    type: 'text'
  },
  {
    displayId: 'am-46',
    id: 'c002a61a-66f3-422f-8b3c-bcb01b0e568a',
    value: 'Connected User',
    type: 'text'
  },
  {
    displayId: 'am-47',
    id: '920803ed-8485-47e3-b1ed-aee396a938f4',
    value: 'Proposed Actions',
    type: 'text'
  },
  {
    displayId: 'am-48',
    id: '4f56a30c-bb35-4fe1-b320-74561b1a9c08',
    value: 'Approved Actions',
    type: 'text'
  },
  {
    displayId: 'am-49',
    id: 'd401566a-a157-4542-bedc-74ef76cabccf',
    value: 'Unapproved',
    type: 'text'
  },
  {
    displayId: 'am-50',
    id: 'e73505d1-db9d-474e-8e12-b2c78bfc909f',
    value: 'Rating',
    type: 'text'
  },
  {
    displayId: 'am-51',
    id: '7398a630-e301-4083-b1ed-0aad8a95bccf',
    value: 'Title',
    type: 'text'
  },
  {
    displayId: 'am-52',
    id: 'cfa4575f-79ca-4d4d-a9ff-a0bc7c258e6e',
    value: 'Description',
    type: 'text'
  },
  {
    displayId: 'am-53',
    id: '62debea3-26f2-42fa-a21d-8c1cb7093953',
    value: 'Approved Action',
    type: 'text'
  },
  {
    displayId: 'am-54',
    id: '6b7e28bf-f264-4ba1-a945-43e3cf2f8916',
    value: 'Unapproved Action',
    type: 'text'
  },
  {
    displayId: 'am-55',
    id: '1467829b-2252-4332-a368-05b7c06c94b3',
    value: 'Total Vote',
    type: 'text'
  },
  {
    displayId: 'am-56',
    id: '70809552-2e1a-45f1-8402-53da4377c096',
    value: 'Due Date',
    type: 'text'
  },
  {
    displayId: 'am-57',
    id: 'faa0b7bb-014c-43ed-b71f-befb8b701418',
    value: 'Welcome',
    type: 'text'
  },
  {
    displayId: 'am-58',
    id: '9707034c-8fd8-4873-ab11-b246aae97715',
    value: 'Yes',
    type: 'text'
  },
  {
    displayId: 'am-59',
    id: '0c4ecb1b-19d4-4290-998a-cb569250b917',
    value: 'No',
    type: 'text'
  },
  {
    displayId: 'am-60',
    id: 'bcc1f317-7c84-44bf-ab15-c24600091d47',
    value: 'No actions found',
    type: 'text'
  }
];

export const DefaultDisplayLanguage: DisplayLanguage = {
  id: '00000000-0000-0000-0000-000000000000',
  name: 'English'
};
