import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import '../styles/components/_image-fullscreen.scss';

const ImageViewWithFullScreen: React.FC<{
  children: React.ReactNode;
  src: string;
  alt: string;
}> = ({ children, src, alt }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  const openFullscreen = () => {
    setIsFullscreen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  // handle outside click image to close fullscreen when click overlay
  const handleOutsideClick = (e: React.MouseEvent) => {
    if (imageRef.current && !imageRef.current.contains(e.target as Node)) {
      closeFullscreen();
    }
  };

  const fullscreenOverlay = (
    <div className="fullscreen-overlay" onClick={handleOutsideClick}>
      <span
        className="material-symbols-outlined close-button"
        onClick={closeFullscreen}>
        close
      </span>
      <div className="fullscreen-content">
        <img src={src} alt={alt} className="fullscreen-image" ref={imageRef} />
      </div>
    </div>
  );

  return (
    <>
      <div className={'cursor-pointer'} onClick={openFullscreen}>
        {children}
      </div>
      {isFullscreen &&
        // create portal so the fullscreen will rendered to outside gallery, which is rendered to body
        ReactDOM.createPortal(fullscreenOverlay, document.body)}
    </>
  );
};

export default ImageViewWithFullScreen;
