import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button } from '@progress/kendo-react-buttons';
import { Window } from '@progress/kendo-react-dialogs';
import {
  TabStrip,
  TabStripTab,
  TabStripSelectEventArguments
} from '@progress/kendo-react-layout';
import { EditorUtils } from '@progress/kendo-react-editor';
import { Col, Row } from 'react-bootstrap';
import { insertImageFiles } from './insert-image-plugin';
import { Media } from '../../types/media';

export const InsertImageDialog = (props: any) => {
  const [selected, setSelected] = React.useState(0);
  const [files, setFiles] = React.useState<Media[]>([]);
  const [src, setSrc] = React.useState<string>();
  const fileUpload = React.useRef<HTMLInputElement>(null);
  let altText: any;
  let title: any;
  let width: any;
  let height: any;

  const onTabSelect = (event: TabStripSelectEventArguments) => {
    setSelected(event.selected);
  };

  const onClose = () => {
    props.onClose.call(undefined);
  };

  const onUploadPicture = (fls: FileList | null) => {
    if (fls) {
      setFiles([{ file: fls[0] }]);
    }
  };

  const onInsert = (currentSrc: string | null) => {
    const { view, imageNode } = props;
    const nodes = view.state.schema.nodes;
    const nodeType = nodes[imageNode];
    const position = null;
    const data = {
      src: src ? src : currentSrc,
      title: title ? title.value : null,
      alt: altText ? altText.value : null,
      width: width ? width.value : null,
      height: height ? height.value : null
    } as any;

    const attrs = Object.keys(data)
      .filter((key) => data[key] !== null && data[key] !== '')
      .reduce((acc, curr) => Object.assign(acc, { [curr]: data[curr] }), {});

    if (files.length && !selected) {
      insertImageFiles({ view, files, nodeType, position, attrs });
    } else {
      const newImage = nodeType.createAndFill(attrs);
      EditorUtils.insertNode(view, newImage, true);
    }

    view.focus();
    onClose();
  };

  const { view, imageNode } = props;
  const state = view && view.state;

  let attrs: any = {};

  if (
    state &&
    state.selection.node &&
    state.selection.node.type === state.schema.nodes[imageNode]
  ) {
    attrs = state.selection.node.attrs;
  }

  const fields = (
    <React.Fragment>
      <div className={'k-edit-label'}>
        <label htmlFor={'k-editor-image-alt'}>Alternate text</label>
      </div>
      <div className={'k-edit-field'}>
        <input
          type={'text'}
          className={'k-textbox'}
          id={'k-editor-image-alt'}
          defaultValue={attrs.alt}
          ref={altText}
        />
      </div>
      <div className={'k-edit-label'}>
        <label htmlFor={'k-editor-image-title'}>Title</label>
      </div>
      <div className={'k-edit-field'}>
        <input
          type={'text'}
          className={'k-textbox'}
          id={'k-editor-image-title'}
          defaultValue={attrs.title}
          ref={title}
        />
      </div>
      {props.resizeImage && (
        <>
          <div className={'k-edit-label'}>
            <label htmlFor={'k-editor-image-width'}>Width (px)</label>
          </div>
          <div className={'k-edit-field'}>
            <input
              type={'text'}
              className={'k-textbox'}
              id={'k-editor-image-width'}
              defaultValue={attrs.width}
              ref={width}
            />
          </div>
          <div className={'k-edit-label'}>
            <label htmlFor={'k-editor-image-height'}>Height (px)</label>
          </div>
          <div className={'k-edit-field'}>
            <input
              type={'text'}
              className={'k-textbox'}
              id={'k-editor-image-height'}
              defaultValue={attrs.height}
              ref={height}
            />
          </div>
        </>
      )}
    </React.Fragment>
  );

  const buttons = (
    <Row className={'mt-3'}>
      <Col
        md={'12'}
        className={'d-flex justify-content-end align-items-center'}>
        <Button
          themeColor={'secondary'}
          className={'w-5 text-light'}
          onClick={onClose}>
          Cancel
        </Button>

        <Button
          themeColor={'primary'}
          className={'w-5 text-light ml-2'}
          onClick={() => onInsert(attrs.src ? attrs.src : null)}>
          Save
        </Button>
      </Col>
    </Row>
  );

  const onUploadClick = () => {
    if (fileUpload && fileUpload.current) {
      fileUpload.current.click();
    }
  };

  const handledelete = (file: Media) => {
    if (files) {
      const fls: Media[] = [...files];

      const index = fls.indexOf(file);
      if (index > -1) {
        fls.splice(index, 1);
      }
      setFiles(fls);
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Window
          title={'Insert Image'}
          onClose={onClose}
          initialWidth={500}
          initialHeight={props.resizeImage ? 540 : 420}>
          <TabStrip
            selected={selected}
            onSelect={onTabSelect}
            animation={false}>
            {Object.entries(attrs).length === 0 && (
              <TabStripTab title={'Upload'}>
                <div className={'k-edit-form-container pt-3 pb-3'}>
                  <div className={'k-edit-label'}>
                    <label htmlFor={'k-editor-image-width'}>Image</label>
                  </div>
                  <div className={'k-edit-field'}>
                    <div className={'col-md-6'}>
                      <Button
                        className={'w-100 upload-button mt-2 mb-2'}
                        onClick={onUploadClick}>
                        <div
                          className={
                            'd-flex justify-content-center align-items-center gap-3'
                          }>
                          <span
                            className={
                              'material-symbols-outlined mr-2 text-success'
                            }
                            role={'button'}>
                            add_photo_alternate
                          </span>
                          <span>Upload</span>
                        </div>
                      </Button>
                      <input
                        type={'file'}
                        id={'file-upload'}
                        ref={fileUpload}
                        multiple={false}
                        className={'d-none'}
                        onChange={(e) => onUploadPicture(e.target.files)}
                        onClick={(event) => {
                          event.currentTarget.value = '';
                        }}
                      />
                    </div>
                    <div className={'col-12'}>
                      <ul className={'list-group w-100'}>
                        {files &&
                          files.map((file, i) => {
                            return (
                              <li
                                className={'list-group-item rounded-0'}
                                key={i}>
                                <div
                                  className={'d-flex justify-content-between'}>
                                  <div className={'d-flex'}>
                                    <span
                                      className={
                                        'material-symbols-outlined mr-2 p-2'
                                      }>
                                      add_photo_alternate
                                    </span>
                                    <span className={'p-2 text-break'}>
                                      {file.file.name}
                                    </span>
                                  </div>
                                  <span
                                    className={
                                      'material-symbols-outlined mr-2 p-2'
                                    }
                                    role={'button'}
                                    onClick={() => handledelete(file)}>
                                    close
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  {fields}
                </div>
              </TabStripTab>
            )}
            <TabStripTab title={'By URL'}>
              <div className={'k-edit-form-container pt-3 pb-3'}>
                <div className={'k-edit-label'}>
                  <label htmlFor={'k-editor-image-url'}>Web address</label>
                </div>
                <div className={'k-edit-field'}>
                  <input
                    type={'text'}
                    className={'k-textbox'}
                    id={'k-editor-image-url'}
                    defaultValue={attrs.src}
                    value={src}
                    disabled={/^data:image/.test(attrs.src || '')}
                    autoFocus={true}
                    onChange={(e) => setSrc(e.target.value)}
                  />
                </div>
                {fields}
              </div>
            </TabStripTab>
          </TabStrip>
          {buttons}
          <style>{`.k-dropzone { width: 100%; }`}</style>
        </Window>,
        document.body,
        'insertImageDialog'
      )}
    </>
  );
};
