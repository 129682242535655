import { useContext, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import HelpSupport from '../help-support';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import SimpleEntity from '../tabs/simple-entity';
import { GameContext } from '../../contexts/game';
import { addNewGroups } from '../../services/groups';

interface CreateGroupProps {
  onSubmitClick?: () => void;
}

export const CreateGroup = ({ onSubmitClick = () => {} }: CreateGroupProps) => {
  const [game] = useContext(GameContext);
  const [groupDialogIsVisible, setGroupDialogIsVisible] =
    useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [notification, setNotification] = useContext(NotificationContext);

  const toggleGroupsDialogHandler = () => {
    setGroupDialogIsVisible((prevState) => !prevState);
  };

  const responseGroupTeamNotification = (message: string) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: 'k-button--gradient'
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onSubmitGroupHandler = (name?: string) => {
    let errorMessage = '';

    if (name === '') {
      errorMessage = 'Group name is required';
    } else if (name && (name.length < 2 || name.length > 128)) {
      errorMessage = 'Please enter a group name that is 2 - 128 characters';
    }

    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    addNewGroups(game.gameCode!, name!)
      .then((response) => {
        if (response) {
          responseGroupTeamNotification('Group successfully added');
          onSubmitClick();
        }

        toggleGroupsDialogHandler();
      })
      .catch(() => {
        setError('Group name already exists');
      });
  };

  return (
    <>
      <div className={'d-flex justify-content-end w-full'}>
        <HelpSupport
          title="Creating & managing groups"
          url="https://forum.catalystglobal.com/t/4669"
        />
        <Button
          themeColor={'success'}
          className={'ml-3'}
          onClick={toggleGroupsDialogHandler}>
          Create group
        </Button>
      </div>

      {groupDialogIsVisible && (
        <SimpleEntity
          inputTitle={'Group name'}
          errorMessage={error}
          onToggleProfileWidget={() => {
            toggleGroupsDialogHandler();
            setError('');
          }}
          onSubmit={onSubmitGroupHandler}
          isShowAvatar={false}
        />
      )}
    </>
  );
};
