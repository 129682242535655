import React from 'react';
import { fromLonLat } from 'ol/proj';
import { LineString } from 'ol/geom';
import { Feature, Overlay } from 'ol';
import { getDistance } from 'ol/sphere';
import { Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';

import { GameContext } from '../contexts/game';
import { PlayerContext } from '../contexts/player';
import { MapContext } from '../components/map/map-context';
import { GameDocumentContext } from '../contexts/game-document';
import { createCustomTaskPosition } from '../utils/game-engine/task-position';
import {
  createCustomPlayerPosition,
  createIllustrationPlayersContainer
} from '../utils/game-engine/player-position';
import { MapIllustrationContext } from '../components/map/map-illustration-context';
import { GetTaskById } from '../utils/game-document/assets';

export const useGameTaskPosition = ({ mapType }: { mapType: string }) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [gameDocument] = React.useContext(GameDocumentContext);
  const [player] = React.useContext(PlayerContext);
  const [hasTaskOverlay, setHasTaskOverlay] = React.useState(false);

  const lineColor =
    gameDocument?.theme?.colors?.find((x) => x.type === 'Info')?.color ||
    "#0DC5E8'";

  const mapContext = React.useMemo(
    () => (mapType === 'illustration' ? mapIllustration : map),
    [mapType, map, mapIllustration]
  );

  //#region Add task element and its overlay
  const addTaskOverlay = React.useCallback(
    (
      id: string,
      name: string,
      iconUrl: string,
      position: Coordinate | [number, number],
      isFacilitator: boolean = false,
      isVisible: boolean = true
    ) => {
      if (mapContext) {
        const taskExist = mapContext.getOverlayById(id);
        // prevent create task overlay more than once

        if (taskExist && !isVisible) {
          removeTaskOverlay(id);
        }

        if (taskExist) return;

        const taskElement = createCustomTaskPosition({
          id,
          name,
          iconUrl,
          isFacilitator,
          onIllustration: mapType === 'illustration'
        });

        if (isVisible) {
          const overlay = new Overlay({
            id,
            position: fromLonLat(position || [0, 0]),
            positioning: 'center-center',
            element: taskElement
          });

          mapContext.addOverlay(overlay);
          setHasTaskOverlay(true);
        }

        // create players container on illustration map
        if (mapType === 'illustration') {
          const containerExist = mapContext.getOverlayById(
            `illustration-players-container-${id}`
          );
          if (containerExist) return;

          const containerElement = createIllustrationPlayersContainer(
            id,
            taskElement,
            []
          );
          if (player.playerState) {
            const { code, name, avatarImage } = player.playerState;
            if (code && name && avatarImage) {
              const playerElement = createCustomPlayerPosition({
                id: code,
                name,
                score: 0,
                avatarUrl: avatarImage,
                inGameSettings: gameDocument.gameDocument?.settings.inGame,
                isFacilitator,
                onIllustration: true
              });
              containerElement.prepend(playerElement);
            }
          }

          const containerOverlay = new Overlay({
            id: `illustration-players-container-${id}`,
            position: fromLonLat(position || [0, 0]),
            positioning: 'top-center',
            element: containerElement,
            offset: [0, 38]
          });

          mapContext.addOverlay(containerOverlay);
        }
      }
    },
    [
      mapType,
      mapContext,
      player.playerState,
      gameDocument.gameDocument?.settings.inGame
    ]
  );

  const removeTaskOverlay = React.useCallback(
    (id: string) => {
      if (mapContext) {
        const getTask = mapContext.getOverlayById(id);
        // if task not found, return
        if (!getTask) return;

        mapContext.removeOverlay(getTask);
      }
    },
    [mapContext]
  );

  const updateTaskOverlay = React.useCallback(
    ({
      id,
      icon,
      name,
      distance
    }: {
      id: string;
      icon?: string;
      name?: string;
      distance?: number;
    }) => {
      const taskIcon = document.getElementById(
        `icon-${id}`
      ) as HTMLImageElement;
      if (taskIcon && icon) {
        taskIcon.src = icon;
      }

      const taskName = document.getElementById(
        `taskName-${id}`
      ) as HTMLSpanElement;
      if (taskName && name) {
        taskName.innerHTML = name;
      }

      const taskDistance = document.getElementById(
        `taskDistance-${id}`
      ) as HTMLSpanElement;
      if (taskDistance) {
        if (distance) {
          taskDistance.classList.remove('d-none');
          taskDistance.innerHTML = `Approx ${distance}m`;
        } else {
          taskDistance.classList.add('d-none');
        }
      }
    },
    []
  );

  const getLineDistance = (): Feature<LineString> | null => {
    const layers = map.getLayers().getArray();
    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source instanceof VectorSource) {
          const features = source.getFeatures();
          for (const feature of features) {
            if (feature.getGeometry() instanceof LineString) {
              return feature as Feature<LineString>;
            }
          }
        }
      }
    }
    return null;
  };

  //#region Remove task line and distance
  const removeTaskDistance = React.useCallback(
    (selectedTaskId?: string) => {
      const taskContainer = document.querySelectorAll('[id^="taskContainer-"]');
      taskContainer.forEach((task) => {
        const taskId = task.id.replace('taskContainer-', '');
        if (selectedTaskId === taskId) return;
        task.classList.remove('expanded');
        task.classList.add('initial');
        const detailTaskContainer = task.querySelector(
          '[id^="detailTaskContainer-"]'
        );
        detailTaskContainer?.classList.remove('visible');
        detailTaskContainer?.classList.add('hidden');
        task.querySelector('[id^="taskDistance-"]')?.classList.add('d-none');
      });

      const layers = map.getAllLayers();
      const lineLayer = layers.find((layer) =>
        layer.getClassName().includes('line-string')
      );
      if (lineLayer) {
        // remove the line
        map.removeLayer(lineLayer);
      }
    },
    [map]
  );

  //#region Add task line and distane
  const addTaskDistance = React.useCallback(
    (id: string, position: Coordinate | [number, number]) => {
      if (map) {
        // hide all tasks distance first
        removeTaskDistance(id);

        const playerLonLat = [
          player.playerState?.coordinates?.longitude || 0,
          player.playerState?.coordinates?.latitude || 0
        ];

        const distances = getDistance(position, playerLonLat);
        updateTaskOverlay({ id, distance: Math.floor(distances) });
        const lineFeature = new Feature({
          geometry: new LineString([
            fromLonLat(position),
            fromLonLat(playerLonLat)
          ])
        });

        const lineStyle = new Style({
          stroke: new Stroke({
            color: lineColor,
            width: 3,
            lineDash: [10, 10]
          })
        });

        const vectorSource = new VectorSource({
          features: [lineFeature]
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
          style: lineStyle,
          className: 'line-string',
          properties: {
            id: 'taskDistance'
          }
        });

        const layers = map.getAllLayers();
        const existingLineLayer = layers.find(
          (layer) => layer.get('id') === 'taskDistance'
        );

        if (existingLineLayer) map.removeLayer(existingLineLayer);
        map.addLayer(vectorLayer);
      }
    },
    [
      map,
      lineColor,
      player.playerState?.coordinates?.latitude,
      player.playerState?.coordinates?.longitude,
      removeTaskDistance,
      updateTaskOverlay
    ]
  );

  return {
    hasTaskOverlay,
    addTaskOverlay,
    addTaskDistance,
    getLineDistance,
    updateTaskOverlay,
    removeTaskOverlay,
    removeTaskDistance
  };
};
