export const createCustomTaskPosition = ({
  id,
  name,
  iconUrl,
  isFacilitator,
  onIllustration
}: {
  id: string;
  name: string;
  iconUrl: string;
  isFacilitator: boolean;
  onIllustration: boolean;
}) => {
  const taskContainer = document.createElement('div');
  taskContainer.classList.add('task-container', 'initial');
  !isFacilitator && taskContainer.classList.add('enable-click');
  taskContainer.id = `taskContainer-${id}`;

  const iconContainer = document.createElement('div');
  iconContainer.classList.add('icon-container');
  const iconCircle = document.createElement('div');
  iconCircle.classList.add('icon-circle');

  const icon = document.createElement('img');
  icon.id = `icon-${id}`;
  icon.src = iconUrl;
  icon.alt = 'icon';
  icon.classList.add('icon-img');
  iconCircle.append(icon);
  iconContainer.append(iconCircle);

  const detailTaskContainer = document.createElement('div');
  detailTaskContainer.classList.add('detail-task-container');
  detailTaskContainer.id = `detailTaskContainer-${id}`;

  const taskName = document.createElement('span');
  taskName.classList.add('fw-bold');
  taskName.id = `taskName-${id}`;
  taskName.innerHTML = name;

  const taskDistance = document.createElement('span');
  taskDistance.id = `taskDistance-${id}`;
  taskDistance.className = 'text-success d-none';
  taskDistance.innerHTML = 'Approx 0m';

  detailTaskContainer.append(taskName, taskDistance);
  detailTaskContainer.classList.add('hidden');

  // Check if device supports touch events
  const isTouchDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  if (!isTouchDevice) {
    taskContainer.addEventListener('mouseenter', () => {
      if (!taskContainer.classList.contains('expanded')) {
        moveTaskToTop(taskContainer, onIllustration);

        setTimeout(() => {
          taskContainer.classList.add('expanded');
          taskContainer.classList.remove('initial');
          detailTaskContainer.classList.add('visible');
          detailTaskContainer.classList.remove('hidden');
        }, 10);
      }
    });

    taskContainer.addEventListener('mouseleave', () => {
      if (taskContainer.classList.contains('expanded')) {
        const noTaskDistance = taskContainer.querySelector(
          `[id^="taskDistance-"].d-none`
        );
        if (noTaskDistance) moveBackTaskFromTop(onIllustration);

        setTimeout(() => {
          taskContainer.classList.remove('expanded');
          taskContainer.classList.add('initial');
          detailTaskContainer.classList.remove('visible');
          detailTaskContainer.classList.add('hidden');
        }, 10);
      }
    });
  }

  taskContainer.append(iconContainer, detailTaskContainer);
  return taskContainer;
};

export const moveTaskToTop = (
  taskContainer: HTMLElement,
  onIllustration: boolean = false
) => {
  const overlaysContainer = document.querySelector(
    '.ol-overlaycontainer-stopevent'
  );

  if (overlaysContainer) {
    overlaysContainer.childNodes.forEach((node, idx) => {
      if (node.contains(taskContainer)) {
        const overlayElements = Array.from(overlaysContainer.childNodes);
        let movedNode: any[] = [node];

        if (onIllustration) {
          const taskId = taskContainer.id.replace('taskContainer-', '');
          const playersContainer = document.querySelector(
            `[id^="otherPlayersContainer-${taskId}"]`
          )?.parentNode?.parentNode;
          if (playersContainer) movedNode.push(playersContainer);
        }

        overlayElements.splice(idx, 1);
        overlayElements.splice(overlayElements.length - 1, 0, ...movedNode);
        overlaysContainer.replaceChildren(...overlayElements);
      }
    });
  }
};

export const moveBackTaskFromTop = (onIllustration: boolean = false) => {
  if (onIllustration) return;

  const overlaysContainer = document.querySelector(
    '.ol-overlaycontainer-stopevent'
  );

  if (overlaysContainer) {
    const players = document.querySelectorAll('.player-container');
    if (players.length === 0) return;

    const playerOverlayElement: any[] = [];
    players.forEach((player) => {
      playerOverlayElement.push(player.parentNode);
      overlaysContainer.removeChild(player.parentNode as ParentNode);
    });
    const overlayElements = Array.from(overlaysContainer.childNodes);
    overlayElements.splice(
      overlayElements.length - 1,
      0,
      ...playerOverlayElement
    );
    overlaysContainer.replaceChildren(...overlayElements);
  }
};
