import { Button } from '@progress/kendo-react-buttons';
import { Error } from '@progress/kendo-react-labels';
import { CardSubtitle } from '@progress/kendo-react-layout';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import {
  GetFileExtensionsBySource,
  dataURItoBlob,
  loadImageToBlobUrl
} from '../../utils/image-helper';
import { GameDocumentContext } from '../../contexts/game-document';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { uuid } from '../../types/common-helper';
import { Dialog } from '@progress/kendo-react-dialogs';
import CameraModal from '../camera-modal';
import ImageViewWithFullScreen from '../image-view-with-fullscreen';

const generateRandomString = () => {
  return uuid().split('-').join('').substring(0, 5);
};

type Props = {
  isReadonly?: boolean;
  selectedImage?: string;
  onSelectedImageCallback?: (blobUrl: string | File, size?: number) => void;
  isDisabled?: boolean;
  formId: string;
};

const AnswerImage = ({
  isReadonly = false,
  selectedImage = '',
  onSelectedImageCallback = () => {},
  isDisabled = false,
  formId
}: Props) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const videoRef = useRef<Webcam>(null);
  const [blobImage, setBlobImage] = useState<string>(selectedImage);
  const [isDisplayWebcam, setIsDisplayWebcam] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<File>();
  const [isValidFileExtension, setIsValidFileExtension] =
    useState<boolean>(true);
  const [facingMode, setFacingMode] = useState<string>('environment');

  const turnOnCameraClick = () => {
    setIsDisplayWebcam(true);
    setIsValidFileExtension(true);
  };

  const turnOffCameraClick = () => {
    const { stream } = videoRef.current!;
    const tracks = stream?.getTracks();
    tracks?.forEach((item) => item.stop());
    setBlobImage('');
    onSelectedImageCallback('');
    setIsDisplayWebcam(false);
  };

  const handleCapture = useCallback(
    (screenShot: string) => {
      fetch(screenShot)
        .then((result) => {
          const imageFile = dataURItoBlob(result.url);
          let myFile = imageFile;
          if (imageFile instanceof Blob) {
            const extension = imageFile.type.split('/')[1];
            myFile = new File(
              [imageFile],
              `captured-image-${generateRandomString()}.${extension}`,
              {
                type: imageFile.type
              }
            );
          }
          onSelectedImageCallback(myFile as File, imageFile.size);
          setBlobImage(result.url);
        })
        .catch((error) => console.error(error))
        .finally(() => setIsDisplayWebcam(false));
    },
    [onSelectedImageCallback]
  );

  const getFileExtension = (filename: string) => {
    // get file extension
    const extension = filename.substring(
      filename.lastIndexOf('.') + 1,
      filename.length
    );
    return extension;
  };

  const allowedImages = () => {
    return (
      getFileExtension(selectedImage).toLocaleLowerCase() === 'png' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'jpg' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'jpeg' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'gif' ||
      getFileExtension(selectedImage).toLocaleLowerCase() === 'heic' ||
      getFileExtension(selectedImage) === ''
    );
  };

  const handleSwitchCamera = useCallback(() => {
    setFacingMode((prev) => (prev === 'user' ? 'environment' : 'user'));
  }, [setFacingMode]);

  return (
    <div id={'answer-image'} className={'d-flex flex-column flex-grow-1 h-100'}>
      <div
        className={
          'd-flex flex-column justify-content-center border text-center position-relative'
        }
        style={{
          flex: 1,
          overflow: 'hidden'
        }}>
        <div
          className={'d-flex justify-content-center align-items-center h-100'}>
          {blobImage === '' || selectedImage === '' ? (
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center'
              }>
              <span
                className={
                  'material-symbols-outlined text-success text-size-64'
                }>
                wallpaper
              </span>
              <span>
                {generateTitleById(
                  '567963b7-47c0-40e9-8659-2e43a5a5befb',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Preview'}
              </span>
            </div>
          ) : allowedImages() ? (
            <ImageViewWithFullScreen
              src={selectedImage ?? blobImage}
              alt={'preview'}>
              <div className="image-preview-container">
                <img
                  src={selectedImage ?? blobImage}
                  alt={'Preview'}
                  className="preview-image"
                />
              </div>
            </ImageViewWithFullScreen>
          ) : (
            <p>
              <span
                className={
                  'material-symbols-outlined text-primary text-size-64'
                }>
                cloud_done
              </span>
              <br />
              {!isReadonly ? (
                (fileUpload?.name ??
                decodeURI(
                  selectedImage.substring(selectedImage.lastIndexOf('/') + 1)
                ))
              ) : (
                <>
                  <span>
                    {decodeURI(
                      selectedImage.substring(
                        selectedImage.lastIndexOf('/') + 1
                      )
                    )}
                  </span>{' '}
                  <br />
                  <a href={selectedImage}>Download</a>
                </>
              )}
            </p>
          )}
        </div>
      </div>

      {/* currently commented as requested from User Story #5365 */}
      {/* {isLargeImageSize && (
        <Row>
          <Error>Please reduce your file size below 500 Kb</Error>
        </Row>
      )} */}
      {!isReadonly && (
        <Row className={'py-2'}>
          {!isValidFileExtension && (
            <Col className={'mb-4'} md={12}>
              <Error>
                {generateTitleById(
                  '4af50ef9-db25-48cf-9fa2-2a5ad295d6cf',
                  gameDocument,
                  displayLanguageContext.displayLanguageSelected.resources!,
                  'game'
                ) || 'Invalid File Type: Please upload image files only.'}
              </Error>
            </Col>
          )}
          {!isDisabled && (
            <div className={'d-flex flex-column gap-2'}>
              <Button
                className={'w-100 upload-button p-2'}
                onClick={turnOnCameraClick}>
                <div
                  className={
                    'd-flex justify-content-center align-items-center gap-3'
                  }>
                  <span
                    className={'material-symbols-outlined mr-2 text-success'}
                    role={'button'}>
                    add_a_photo
                  </span>
                  <span>
                    {generateTitleById(
                      '7b63892e-236c-4b6a-bfec-d5d3d66794af',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Take photo'}
                  </span>
                </div>
              </Button>
            </div>
          )}
        </Row>
      )}

      <CameraModal
        isOpen={isDisplayWebcam}
        onClose={() => setIsDisplayWebcam(false)}
        onCapture={handleCapture}
        facingMode={facingMode}
        onSwitchCamera={handleSwitchCamera}
      />
    </div>
  );
};

export default AnswerImage;
