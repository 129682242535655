import React, { useEffect, useRef, useState, useContext } from 'react';
import TileLayer from 'ol/layer/Tile';
import { BingMaps, OSM } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Input, Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import SelectionItem from '../../types/selection-item';
import {
  getAllLocations,
  getPlayerLocationColorStatus
} from '../../utils/game-document/location';
import { GameDocumentContext } from '../../contexts/game-document';
import { Col, Row } from 'react-bootstrap';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { GetMapByZoneId, GetZoneById } from '../../utils/game-document/assets';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { MapEntity, ZoneEntity } from '../../types/game-document/entities';
import { BingMapsKey } from '../../constants/bing-key';
import CircleStyle from 'ol/style/Circle';
import { Color } from 'ol/color';
import { GameContext } from '../../contexts/game';
import { Style, Fill, Stroke } from 'ol/style';
import {
  DefaultLineStroke,
  DefaultPolygonFillColor,
  DefaultRadius,
  DefaultZoomLevel,
  OfflinePlayerColor,
  PolygonLineColor
} from '../../constants/map';
import { GetResourceValue } from '../../utils/game-document/resources';
import { GetPointStyle } from '../../utils/map-helper';
import { getGroupList } from '../../services/groups';
import { GroupResponse } from '../../types/responses/group-response';
import { roundingDecimalNumber } from '../../utils/number';
import FacilitatorIllustrationMap from './map/facilitator-map-illustration';
import { PlayerState } from '../../types/state';
import { FilterView, Menu } from '../../types/facilitator/map-menu';
import { MapMenu } from './map/map-menu';
import { useGamePlayerPosition } from '../../hooks/use-game-player-position';
import { MapContext } from '../../components/map/map-context';
import { useGameTask } from '../../hooks/use-game-task';
import { useGameMapOverlay } from '../../hooks/use-game-map-overlay';
import HelpSupport from '../../components/help-support';

export const MapViewPage = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [gameState] = useContext(GameContext);
  const mapElement = useRef<HTMLDivElement>(null);
  const [locations] = useState<SelectionItem[]>(
    getAllLocations(gameDocument.gameDocument!)
  );
  const [isOpenStreetMap, setIsOpenStreetMap] = useState<boolean>(false);
  const [selectedZoneId, setSelectedZoneId] = useState<string>('world-map');
  const [selectedZoneIllustrationId, setSelectedZoneIllustrationId] =
    useState<string>('world-map');
  const [isGPSMap, setIsGPSMap] = useState<boolean>(true);
  const [worldMap] = useState<MapEntity>(
    gameDocument!.gameDocument!.assets!.maps![0] ?? {}
  );
  const [search, setSearch] = useState<string>('');
  const [menus, setMenus] = useState<Menu[]>([]);
  const [selectedMenu, setSelectedMenu] = useState<Menu>();
  const defaultZoomLevel: number = DefaultZoomLevel;
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const objectLineColor: string | Color = PolygonLineColor;
  const filterData: FilterView[] = [
    { id: 'tasks', description: 'Tasks' },
    { id: 'players', description: 'Players' },
    { id: 'offline', description: 'Offline' }
  ];
  const [geoJSON, setGeoJSON] = useState<any>();
  const [filterViewsSelected, setFilterViewSelected] =
    useState<FilterView[]>(filterData);

  const map = useContext(MapContext);
  const [isIllustrationMapLoaded, setIsIllustrationMapLoaded] =
    useState<boolean>(false);

  const { loadImageOverlay } = useGameMapOverlay();
  const { addAllPlayer, addAllPlayerOnIllustration } = useGamePlayerPosition({
    mapType: worldMap.type
  });
  const { resetAllTaskOverlay, populateTaskOverlay } = useGameTask({
    mapType: worldMap.type,
    selectedZoneId,
    isFacilitator: true
  });

  const generateMenus = async () => {
    // Fetch group list
    const groupsResult = (await getGroupList(
      gameState?.gameCode!
    )) as GroupResponse[];
    const groups =
      groupsResult?.map((group) => ({
        name: group?.name,
        code: group?.code,
        type: 'group',
        score: ''
      })) || [];

    // Extract teams
    const teams =
      gameState?.gameState?.teams
        ?.map((team) => ({
          name: team.name,
          code: team.code,
          type: 'team',
          score: roundingDecimalNumber(team?.score ?? 0, 2) ?? ''
        }))
        ?.sort((a, b) => b.score - a.score) || [];

    // Extract players
    const players =
      gameState?.gameState?.players
        ?.map((player) => ({
          name: player.name,
          code: player.code,
          type: 'player',
          score: roundingDecimalNumber(player?.score ?? 0, 2) ?? '',
          statusColor: getPlayerLocationColorStatus(
            gameState?.gameState?.players?.find((x) => x.code === player?.code)
              ?.lastUpdated
          ),
          location: getPlayerLocation(player),
          locationId: player.location
        }))
        ?.sort((a, b) => b.score - a.score) || [];

    // Merge all items into a single array
    const menuItems = [...groups, ...teams, ...players];
    setMenus(menuItems as Menu[]);
  };

  const getPlayerLocation = (playerState: PlayerState) => {
    if (playerState.location === '' || !playerState?.location) {
      return '--';
    } else if (playerState.location === 'world-map') {
      return 'world-map';
    } else {
      let zone = GetZoneById(
        gameDocument!.gameDocument!,
        playerState!.location!
      );
      return (
        GetResourceValue(gameDocument!.gameDocument!, zone!.titleResId!, '') ??
        '--'
      );
    }
  };

  const onViewChangeMapType = (e: SwitchChangeEvent) => {
    setIsOpenStreetMap(e.target.value);
  };

  /**
   * Change map to satelite view or openstreet view
   */
  const changeMapType = () => {
    const tileLayer = new TileLayer({
      source: isOpenStreetMap
        ? new OSM()
        : new BingMaps({
            key: BingMapsKey,
            imagerySet: 'Aerial',
            maxZoom: defaultZoomLevel
          })
    });

    //Update the map layers
    const layers = map.getAllLayers();
    if (layers.length > 0) {
      layers[0] = tileLayer;
      map.setLayers(layers);
    } else {
      map.setLayers([tileLayer]);
    }
  };

  /**
   * Navigate to world map & remove all feature layer
   */
  const navigateToWorldMap = () => {
    setIsGPSMap(worldMap.type !== 'illustration');
    navigateToZone(true);

    const view = map.getView();
    view.setCenter(fromLonLat([worldMap.longitude!, worldMap.latitude!]));
    view.setZoom(worldMap.zoomLevel ?? defaultZoomLevel);
  };

  /**
   * Styling for zone
   * @returns
   */
  const zoneStyle = (): Style[] => {
    const zoneStyle = new Style({
      geometry: function (feature) {
        const modifyGeometry = feature.get('modifyGeometry');
        return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      },
      fill: new Fill({
        color: DefaultPolygonFillColor
      }),
      stroke: new Stroke({
        color: objectLineColor,
        width: DefaultLineStroke
      }),
      image: new CircleStyle({
        radius: DefaultRadius,
        fill: new Fill({
          color: objectLineColor
        })
      })
    });

    return [zoneStyle];
  };

  /**
   * Navigate to zone
   */
  const navigateToZone = (isNavigateToZone: boolean) => {
    let geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:3857'
        }
      },
      features: []
    };

    const zone: ZoneEntity | undefined =
      GetZoneById(gameDocument!.gameDocument, selectedZoneId) ?? undefined;

    if (zone && isGPSMap) {
      let selectedMap: MapEntity | undefined = GetMapByZoneId(
        gameDocument.gameDocument!,
        zone.id
      );

      //Populate selected zone
      geojsonObject.features.push(zone!.boundary as never);
    }

    setGeoJSON(geojsonObject);
    populateGeoJSON(geojsonObject, zone, isNavigateToZone);
  };

  /**
   * Populate geo json object to vector layer
   * @param geojsonObject
   * @param zone
   * @param isNavigateToZone
   */
  const populateGeoJSON = (
    geojsonObject: any,
    zone: ZoneEntity | undefined,
    isNavigateToZone: boolean
  ) => {
    //Create vector layer datasource from geo json object
    let source = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject)
    });

    //Create the vector layer
    let vector = new VectorLayer({
      source: source,
      style: function (geojsonObject) {
        const type = geojsonObject.getGeometry()?.getType();
        const featureObjectType = geojsonObject.get('objectType');
        if (featureObjectType === 'Task') {
          const task = gameDocument?.gameDocument?.assets?.tasks?.find((x) => {
            return x.boundary?.id === geojsonObject.getId();
          });
          const imgUrl = GetResourceValue(
            gameDocument!.gameDocument!,
            task!.imageResId!,
            ''
          );

          let taskName = GetResourceValue(
            gameDocument!.gameDocument!,
            task!.titleResId!,
            ''
          );

          return GetPointStyle(
            imgUrl ?? 'https://cdn.catalystglobal.games/resources/map-task.png',
            taskName
          );
        } else {
          //Styling for zone
          if (type === 'Polygon') {
            return zoneStyle();
          }
        }
      }
    });

    const layers = map.getAllLayers();
    //Update layer with the index 1 (Vector layer)
    if (layers.length > 1) {
      layers[2] = vector;
      map.setLayers(layers);
    } else {
      //Add layer if there is no vector layer
      map.addLayer(vector);
    }

    let features = source.getFeatures();

    //Set animation when navigate to zone
    const fitOptions = { duration: 1000 };

    if (zone && isNavigateToZone) {
      features?.forEach((ft) => {
        if (ft.getId() === zone?.boundary?.id) {
          let geo = ft.getGeometry() as SimpleGeometry;

          if (geo) {
            map.getView().fit(geo, fitOptions);
          }
        }
      });
    }
  };

  /**
   * On click zone submit button
   */
  const zoneDropdwonHandler = () => {
    if (!isGPSMap) {
      setSelectedZoneIllustrationId(selectedZoneId);
    }

    if (selectedZoneId === 'world-map') {
      navigateToWorldMap();
    } else {
      navigateToZone(true);
    }
  };

  const applyFilter = (
    elements: HTMLCollectionOf<Element>,
    filter: boolean
  ) => {
    for (let i = 0; i < elements.length; i++) {
      if (filter) {
        elements[i].classList.add('d-none');
      } else {
        elements[i].classList.remove('d-none');
      }
    }
  };

  useEffect(() => {
    if (map) {
      map.setTarget(mapElement.current!);
      map.getView().setZoom(worldMap!.zoomLevel ?? defaultZoomLevel);

      map
        .getView()
        .setCenter([worldMap.longitude ?? 0, worldMap.latitude ?? 0]);
    }
  }, [map, mapElement]);

  useEffect(() => {
    changeMapType();
  }, [isOpenStreetMap]);

  useEffect(() => {
    navigateToWorldMap();
    generateMenus();
  }, []);

  useEffect(() => {
    navigateToZone(false);
  }, [gameState.gameState?.players]);

  useEffect(() => {
    navigateToZone(false);
  }, [selectedMenu, filterViewsSelected]);

  useEffect(() => {
    if (gameState.gameState || isIllustrationMapLoaded) {
      if (worldMap.type !== 'illustration') addAllPlayer(true);
      populateTaskOverlay();
    }
  }, [
    worldMap.type,
    gameState.gameState,
    isIllustrationMapLoaded,
    addAllPlayer,
    populateTaskOverlay
  ]);

  React.useEffect(() => {
    addAllPlayerOnIllustration(true);
  }, [
    worldMap.type,
    gameState.gameState?.teams,
    gameState.gameState?.players,
    gameDocument.gameDocument?.settings.inGame.showOtherPlayerOrTeamOnMap
  ]);

  useEffect(() => {
    resetAllTaskOverlay();
    loadImageOverlay(selectedZoneId);

    return () => {
      map.setLayers([
        ...map
          .getLayers()
          .getArray()
          .filter((layer) => layer.get('id') !== 'gpsOverlay')
      ]);
    };
  }, [worldMap.type, selectedZoneId]);

  useEffect(() => {
    const filterView = filterViewsSelected.map((item) => item.id);
    const showTasks = filterView.includes('tasks');
    const showPlayers = filterView.includes('players');
    const showOfflinePlayers = filterView.includes('offline');

    const tasks = document.getElementsByClassName('task-container');
    applyFilter(tasks, !showTasks);

    const players = document.getElementsByClassName('player-container');
    const playerList = document.getElementsByClassName(
      'other-players-container'
    );
    applyFilter(players, !showPlayers);
    applyFilter(playerList, !showPlayers);

    const offline = document.getElementsByClassName('player-container offline');
    const offlinePlayerList = document.getElementsByClassName(
      'other-players-item offline'
    );
    applyFilter(offline, !showOfflinePlayers);
    applyFilter(offlinePlayerList, !showOfflinePlayers);
  }, [filterViewsSelected]);

  return (
    <div className={'d-flex flex-column gap-2 h-100'}>
      <Row>
        <Col>
          <div className={'d-flex align-items-start flex-column'}>
            <label className={'fw-bold text-dark'}>Select zone</label>
          </div>
          <DropDownList
            data={locations}
            textField={'name'}
            dataItemKey={'id'}
            value={locations?.find((x) => x.id === selectedZoneId)}
            className={'w-20 border mr-2'}
            onChange={(e) => {
              setSelectedZoneId(e.value.id);
            }}
          />
          <Button themeColor={'success'} onClick={zoneDropdwonHandler}>
            Submit
          </Button>
        </Col>
        <Col>
          <HelpSupport
            title="Map view guide"
            url="https://forum.catalystglobal.com/t/4621"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={'d-flex align-items-center'}>
            <Input
              placeholder={'Search..'}
              value={search}
              className={'w-20 border mr-2'}
              onChange={(e) => setSearch((e?.target?.value as string) ?? '')}
            />
            <span
              style={{ fontSize: 'xx-large' }}
              className="material-symbols-outlined text-dark cursor-pointer"
              onClick={() => setIsExpand(!isExpand)}>
              {isExpand ? 'expand_circle_down' : 'menu'}
            </span>
          </div>
        </Col>
        <Col>
          <div className={'d-flex justify-content-end align-items-center'}>
            <MultiSelect
              className={'w-50'}
              data={filterData}
              value={filterViewsSelected}
              onChange={(e) => {
                setFilterViewSelected(e.target.value);
              }}
              textField={'description'}
              dataItemKey={'id'}
              placeholder="Filter view"
            />
            <Button
              themeColor={'success'}
              className={'ml-2'}
              onClick={() => {
                setSearch('');
                setFilterViewSelected(filterData);
                setSelectedMenu(undefined);
              }}>
              Reset
            </Button>
          </div>
        </Col>
      </Row>

      <div className={'layout-facilitator-map-container h-100'}>
        <div className={`map-menu w-20 ${!isExpand ? 'd-none' : ''}`}>
          <div className="list-group w-100 bg-transparent">
            {menus
              ?.filter((x) =>
                x.name?.toLowerCase()?.includes(search?.toLowerCase())
              )
              ?.map((menu) => (
                <MapMenu
                  key={menu.code}
                  menu={menu}
                  selectedMenu={selectedMenu}
                  onChange={(v) => setSelectedMenu(v)}
                />
              ))}
          </div>
        </div>
        {!isGPSMap && (
          <div className="map-player-location">
            <div className="list-group w-20 bg-transparent">
              <small className="p-2">Players in this location</small>
              {menus
                ?.filter(
                  (x) =>
                    x.name?.toLowerCase()?.includes(search?.toLowerCase()) &&
                    x.type === 'player' &&
                    x.locationId === selectedZoneId
                )
                ?.map((menu) => {
                  if (
                    filterViewsSelected?.find((x) => x.id === 'players') ||
                    (!filterViewsSelected?.find((x) => x.id === 'players') &&
                      filterViewsSelected?.find((x) => x.id === 'offline') &&
                      menu.statusColor?.toString() === OfflinePlayerColor)
                  ) {
                    return (
                      <MapMenu
                        key={menu.code}
                        menu={menu}
                        selectedMenu={selectedMenu}
                        showLocation={false}
                      />
                    );
                  } else {
                    return <React.Fragment key={menu.code} />;
                  }
                })}
            </div>
          </div>
        )}

        {isGPSMap ? (
          <div className={`layout-facilitator--mapview`} ref={mapElement}>
            <div className={'map-view-option'}>
              <span>Street View</span>
              <Switch
                size={'small'}
                onLabel={'Street View'}
                offLabel={'Satelite View'}
                onChange={(e) => onViewChangeMapType(e)}
                checked={isOpenStreetMap}
              />
            </div>
          </div>
        ) : (
          <FacilitatorIllustrationMap
            geoJSON={geoJSON}
            selectedMap={selectedZoneIllustrationId}
            setIsIllustrationMapLoaded={setIsIllustrationMapLoaded}
          />
        )}
      </div>
    </div>
  );
};
