import { useContext, useEffect, useState } from 'react';
import { Toast, ToastBody, ToastContainer } from 'react-bootstrap';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { last } from 'lodash';
import { uuid } from '../../types/common-helper';

const NotificationAlert = () => {
  const [notification, setNotification] = useContext(NotificationContext);
  const [uniqueId, setUniqueId] = useState(uuid());

  const handleOnClose = (data: NotificationContent): void => {
    setNotification({
      ...notification,
      content: notification.content.map((notif) => ({ ...notif, isHide: true }))
    });

    if (data && data.onCloseCallback) data.onCloseCallback();
  };

  const latestNotif = last(notification.content);

  useEffect(() => {
    setUniqueId(uuid());
  }, [latestNotif]);

  if (!latestNotif) return <></>;

  return (
    <ToastContainer
      className={'p-3'}
      position={notification.position}
      style={{
        zIndex: 9999
      }}>
      <Toast
        key={uniqueId}
        onClose={() => handleOnClose(latestNotif)}
        show={!latestNotif.isHide}
        delay={latestNotif?.hideDelay ?? 7000}
        autohide={latestNotif?.autohide ?? true}
        className={`${latestNotif.color} py-2`}
        style={{ borderRadius: '10px' }}>
        <ToastBody
          className={
            'd-flex flex-row justify-content align-items-center text-white p-2'
          }>
          <div className={'mx-1'}>
            <span
              className={'material-symbols-outlined'}
              style={{ fontSize: '32px' }}
              color={'white'}>
              {latestNotif.icon}
            </span>
          </div>
          <div className={'mx-3 flex-grow-1'}>
            <span style={{ fontSize: '12px' }}>{latestNotif.message}</span>
          </div>
          {(latestNotif?.isShowCloseButton ?? true) && (
            <div className={'mx-1'}>
              <span
                role={'button'}
                className={'material-symbols-outlined text-dark fw-bold'}
                onClick={() => handleOnClose(latestNotif)}>
                close
              </span>
            </div>
          )}
        </ToastBody>
      </Toast>
    </ToastContainer>
  );
};

export default NotificationAlert;
