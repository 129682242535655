import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { GameAlgorithm } from '../../../components/game-algorithm';
import { useContext, useEffect, useState } from 'react';
import {
  giveItem as groupGiveItem,
  giveScore as groupGiveScore,
  giveTitle as groupGiveTitle,
  navigateToWorldMap as groupNavigateToWorldMap,
  navigateToZone as groupNavigateToZone,
  showArea as groupShowArea,
  openTask as groupOpenTask,
  showTask as groupShowTask,
  pauseTimer as groupPauseTimer,
  startTimer as groupStartTimer
} from '../../../services/groups';
import {
  NotificationContent,
  NotificationContext
} from '../../../contexts/notification';
import { getGroupList, joinGroup } from '../../../services/groups';
import { GameContext } from '../../../contexts/game';
import { GroupResponse } from '../../../types/responses/group-response';

interface AllGroupFacilitatorStateProps {
  isDisableConfirm?: boolean;
}

interface AlgorithmState {
  operation?: string;
  argument?: string | string[];
  quantity?: number;
}

export const AllGroupFacilitatorState = ({
  isDisableConfirm = false
}: AllGroupFacilitatorStateProps) => {
  const [game] = useContext(GameContext);
  const [groups, setGroups] = useState<GroupResponse[]>([]);
  const [algorithmState, setAlgorithmState] = useState<AlgorithmState>({});
  const [notification, setNotification] = useContext(NotificationContext);

  const getAllGroups = async () => {
    let response = await getGroupList(game.gameCode!);
    setGroups((response as GroupResponse[]) ?? []);
  };

  const onChangeOperationHandler = (operation?: string) => {
    setAlgorithmState({ ...algorithmState, operation: operation });
  };

  const onChangeArgumentHandler = (selectedArguments?: string | string[]) => {
    setAlgorithmState({ ...algorithmState, argument: selectedArguments });
  };

  const onChangeQuantityHandler = (quantity?: number) => {
    setAlgorithmState({ ...algorithmState, quantity: quantity });
  };

  const responseGroupNotification = (
    message: string,
    isError: boolean = false
  ) => {
    const responseTeamNotification: NotificationContent = {
      icon: 'notifications',
      isHide: false,
      message: (
        <span>
          <strong>{message}</strong>
        </span>
      ),
      color: `${isError ? 'k-button--gradient-error' : 'k-button--gradient'}`
    };
    const content: NotificationContent[] = notification.content;
    content.push(responseTeamNotification);
    setNotification({ ...notification, content });
  };

  const onConfirmHandler = async () => {
    for (const group of groups) {
      const code = group.code;

      switch (algorithmState?.operation) {
        case 'navigateToWorldMap':
          groupNavigateToWorldMap(game.gameCode!, code!);
          break;
        case 'navigateToZone':
          groupNavigateToZone(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string
          );
          break;
        case 'openTask':
          groupOpenTask(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string
          );
          break;
        case 'showTask':
          groupShowTask(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'hideTask':
          groupShowTask(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'showArea':
          groupShowArea(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'hideArea':
          groupShowArea(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'startTimer':
          groupStartTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'stopTimer':
          groupStartTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'pauseTimer':
          groupPauseTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'resumeTimer':
          groupPauseTimer(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        case 'giveItem':
          groupGiveItem(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            algorithmState?.quantity!,
            true
          );
          break;
        case 'removeItem':
          groupGiveItem(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            algorithmState?.quantity! * -1,
            false
          );
          break;
        case 'giveScore':
          groupGiveScore(
            game.gameCode!,
            code!,
            algorithmState?.quantity!,
            true
          );
          break;
        case 'removeScore':
          groupGiveScore(
            game.gameCode!,
            code!,
            algorithmState?.quantity! * -1,
            false
          );
          break;
        case 'giveTitle':
          groupGiveTitle(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            true
          );
          break;
        case 'removeTitle':
          groupGiveTitle(
            game.gameCode!,
            code!,
            algorithmState?.argument! as string,
            false
          );
          break;
        default:
          break;
      }
    }

    responseGroupNotification('Successfully sent the command');
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  return (
    <Row className={'d-flex flex-column gap-4'}>
      <Col md={{ span: 12 }} className={'pl-1'}>
        <hr className={'ml-2'} />
        <div>
          <label className={'pl-2 text-secondary'}>Manual Action</label>
          <div
            className={
              'd-flex justify-content-between align-items-center w-100'
            }>
            <div>
              <GameAlgorithm
                onChangeOperation={onChangeOperationHandler}
                onChangeArgument={onChangeArgumentHandler}
                onChangeQuantity={onChangeQuantityHandler}
                disabled={isDisableConfirm}
              />
            </div>
            <Button
              themeColor={'success'}
              className={'w-8 ml-2'}
              disabled={isDisableConfirm}
              onClick={onConfirmHandler}>
              Confirm
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
