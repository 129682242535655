import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useContext,
  useMemo
} from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import { PlayerContext } from '../../contexts/player';
import { GetResourceValue } from '../../utils/game-document/resources';

/**
 * Renders the game logo image.
 * @remarks Relies on game document context.
 */
export const GameLogo = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [player] = useContext(PlayerContext);
  const logoResource = useMemo(
    () =>
      GetResourceValue(
        gameDocument.gameDocument!,
        gameDocument.gameDocument!.overview?.logoResId!,
        player?.playerState?.language?.name! ?? ''
      ),
    [gameDocument.gameCode]
  );

  return logoResource ? (
    <img src={logoResource} {...props} />
  ) : (
    <div {...props} />
  );
};
