import { useCallback, useRef, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import Webcam from 'react-webcam';

const Button = ({
  children,
  additionalClass,
  onClick
}: {
  children: React.ReactNode;
  additionalClass: string;
  onClick: () => void;
}) => {
  return (
    <div
      className={`d-flex align-items-center cursor-pointer justify-content-center rounded-circle p-2 border border-black ${additionalClass}`}
      style={{ width: '50px', height: '50px' }}
      onClick={onClick}>
      {children}
    </div>
  );
};

type CameraModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCapture: (imageData: string) => void;
  onVideoCapture?: (blob: Blob, url: string) => void;
  facingMode: string;
  onSwitchCamera: () => void;
  mode?: 'photo' | 'video';
};

const CameraModal = ({
  isOpen,
  onClose,
  onCapture,
  onVideoCapture,
  facingMode,
  onSwitchCamera,
  mode = 'photo'
}: CameraModalProps) => {
  const videoRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  const handlePhotoCapture = useCallback(() => {
    const screenShot = videoRef.current?.getScreenshot();
    if (screenShot && screenShot !== '') {
      onCapture(screenShot);
    }
  }, [onCapture]);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(videoRef?.current?.stream!, {
      mimeType: MediaRecorder.isTypeSupported('video/mp4')
        ? 'video/mp4'
        : 'video/webm'
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [videoRef, setCapturing]);

  const handleDataAvailable = useCallback(
    ({ data }: BlobEvent) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => [...prev, data]);
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  const handleSubmitVideo = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: MediaRecorder.isTypeSupported('video/mp4')
          ? 'video/mp4'
          : 'video/webm'
      });
      const url = URL.createObjectURL(blob);
      if (onVideoCapture) {
        onVideoCapture(blob, url);
      }
      setRecordedChunks([]);
    }
  }, [recordedChunks, onVideoCapture]);

  if (!isOpen) return null;

  return (
    <Dialog title="" onClose={onClose} className="webcam-modal">
      <div className="webcam-container position-relative h-100">
        <div className="camera-controls position-absolute top-0 end-0 p-3 d-flex gap-3">
          <Button additionalClass="bg-white" onClick={onSwitchCamera}>
            <span className="material-symbols-outlined">cameraswitch</span>
          </Button>

          <Button additionalClass="bg-white" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <Webcam
          id={'answer-webcam'}
          audio={mode === 'video'}
          videoConstraints={{
            width: 1366,
            height: window.innerHeight,
            facingMode: facingMode
          }}
          screenshotFormat={'image/jpeg'}
          autoPlay={true}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          ref={videoRef}
        />

        <div className="camera-actions position-absolute bottom-0 start-50 translate-middle-x p-4">
          {mode === 'photo' ? (
            <Button additionalClass="bg-white" onClick={handlePhotoCapture}>
              <span className="material-symbols-outlined">photo_camera</span>
            </Button>
          ) : (
            <>
              {capturing ? (
                <Button
                  additionalClass="bg-danger"
                  onClick={handleStopCaptureClick}>
                  <span className="material-symbols-outlined">stop_circle</span>
                </Button>
              ) : recordedChunks.length > 0 ? (
                <div className="d-flex gap-3">
                  <Button
                    additionalClass="bg-danger"
                    onClick={() => setRecordedChunks([])}>
                    <span className="material-symbols-outlined">close</span>
                  </Button>

                  <Button
                    additionalClass="bg-success"
                    onClick={handleSubmitVideo}>
                    <span className="material-symbols-outlined">check</span>
                  </Button>
                </div>
              ) : (
                <Button
                  additionalClass="bg-white"
                  onClick={handleStartCaptureClick}>
                  <span className="material-symbols-outlined">videocam</span>
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default CameraModal;
