import React from 'react';

interface HelpSupportProps {
  title: string;
  url: string;
}

const HelpSupport: React.FC<HelpSupportProps> = ({ title, url }) => {
  return (
    <span style={{ display: 'flex', justifyContent: 'end' }}>
      <a
        href={url}
        target="_blank"
        className="text-success d-flex align-items-center text-decoration-none gap-1">
        <span className="material-symbols-outlined m-0 p-0">help_center</span>
        <u>{title}</u>
      </a>
    </span>
  );
};

export default HelpSupport;
