import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useState
} from 'react';
import { useToggle } from 'usehooks-ts';

export interface LayoutProviderState {
  navIsExpanded: boolean;
  activeMenu: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  toggleNavIsExpanded: () => void;
}

const LayoutContext = createContext<LayoutProviderState>({
  navIsExpanded: true,
  activeMenu: 'Overview',
  setActiveMenu: () => {},
  toggleNavIsExpanded: () => {}
});

/**
 * Creates a layout provider component that manages the state of the layout.
 * @param {PropsWithChildren} props - The properties passed to the component.
 * @returns {ReactElement} The rendered layout provider component.
 */
const LayoutProvider = (props: PropsWithChildren) => {
  const [navIsExpanded, toggleNavIsExpanded] = useToggle(true);
  const [activeMenu, setActiveMenu] = useState('Overview');
  const value = useMemo(
    () => ({
      activeMenu,
      navIsExpanded,
      setActiveMenu,
      toggleNavIsExpanded
    }),
    [navIsExpanded, activeMenu]
  );

  return (
    <LayoutContext.Provider value={value}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
