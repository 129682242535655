import { cloneDeep, uniqBy } from 'lodash';
import { useAuth } from 'react-oidc-context';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
  ChatFromFacilitator,
  PostChatFromFacilitator,
  getPlayers,
  getPlayersChatHistoryAsync
} from '../../services/players';
import {
  FacilitatorPlayerChatContext,
  FacilitatorPlayerChatContextProps
} from '../../contexts/facilitator-player-chat';
import { FacilitatorTeamChat } from './chat/team-chat';
import { FacilitatorGroupChat } from './chat/group-chat';
import { sendFacilitatorChat } from '../../services/games';
import { FacilitatorAllteamChat } from './chat/allteam-chat';
import { GameDocumentContext } from '../../contexts/game-document';
import { TeamResponse } from '../../types/responses/team-response';
import { GroupResponse } from '../../types/responses/group-response';
import { PlayerResponse } from '../../types/responses/player-response';
import { FacilitatorAndPlayerChat } from './chat/facilitator-player-chat';
import { FacilitatorBetweenPlayerChat } from './chat/between-player-chat';
import { getTeamList, sendFacilitatorTeamChat } from '../../services/teams';
import { getGroupList, sendFacilitatorGroupChat } from '../../services/groups';
import { FacilitatorList } from '../../components/facilitator/facilitator-list';
import { PlayerContactResponse } from '../../types/responses/player-contacts-reponse';
import HelpSupport from '../../components/help-support';

export const ChatPage = () => {
  const [gameDocument] = useContext(GameDocumentContext);
  const {
    setNewChat,
    players,
    setPlayers,
    teams,
    setTeams,
    createNewChatConnection
  } = useContext(
    FacilitatorPlayerChatContext
  ) as FacilitatorPlayerChatContextProps;
  const [selectedItem, setSelectedItem] = useState<
    TeamResponse | GroupResponse | PlayerResponse | PlayerContactResponse
  >({});
  const [playersChatHistory, setPlayersChatHistory] = useState<
    PlayerContactResponse[]
  >([]);
  type ChatType = 'Player' | 'PlayerHistory' | '';
  const [chatType, setChatType] = useState<ChatType>('');

  let selectedTeamItem = selectedItem as TeamResponse;
  let selectedGroupItem = selectedItem as GroupResponse;

  const getPlayersChatHistory = useCallback(async () => {
    let result = (await getPlayersChatHistoryAsync(
      gameDocument?.gameCode!
    )) as any;
    setPlayersChatHistory(result as PlayerContactResponse[]);
  }, [gameDocument?.gameCode]);

  const auth = useAuth();
  const userId = parseInt(auth.user?.profile.sub!);
  const userName = auth.user?.profile.name!;

  const getListOfTeam = useCallback(
    async (gameCode: string) => {
      const newTeams: TeamResponse[] | GroupResponse[] = [];
      //get group list
      const groupsResult = (await getGroupList(gameCode)) as GroupResponse[];
      groupsResult.forEach((group) => {
        if (group.code) createNewChatConnection('group', group.code);
      });

      //get team list
      const teamsResponse = (await getTeamList(gameCode)) as TeamResponse[];
      teamsResponse.forEach((team) => {
        if (team.teamCode) createNewChatConnection('team', team.teamCode);
      });

      //set All Teams First on top list.
      newTeams.push({ id: 0, name: 'All teams' });

      //set group list
      if (groupsResult) {
        groupsResult.forEach((group) => {
          newTeams.push(group);
        });
      }

      //set team list
      if (teamsResponse) {
        teamsResponse?.forEach((team) => {
          newTeams.push(team);
        });
        setTeams((prev) => {
          const result = (newTeams as TeamResponse[]).filter(
            (team) => !prev.some((currentTeam) => currentTeam.id === team.id)
          );
          return prev.length === 0
            ? newTeams
            : uniqBy([...prev, ...result], (team) => team.id);
        });
      }
    },
    [createNewChatConnection, setTeams]
  );

  const getAllPlayers = useCallback(
    async (gameCode: string) => {
      const playersResult = (await getPlayers(gameCode)) as PlayerResponse[];
      playersResult.forEach((player) => {
        if (player.code) createNewChatConnection('player', player.code);
      });

      setPlayers((prev) => {
        const newPlayer = playersResult.filter(
          (player) =>
            !prev.some((currentPlayer) => currentPlayer.id === player.id)
        );
        return prev.length === 0
          ? playersResult
          : uniqBy([...prev, ...newPlayer], (player) => player.id);
      });
    },
    [createNewChatConnection, setPlayers]
  );

  const showPlayerIsNewChat = useCallback(
    (playerCode: string, isNewChat: boolean) => {
      setPlayers((prev) => {
        const playerTemp: PlayerResponse[] = cloneDeep(prev);
        let playerIndex = playerTemp.findIndex((x) => x.code === playerCode);
        if (playerIndex > -1) {
          playerTemp[playerIndex].isNewChat = isNewChat;
        }

        const result = playerTemp.map((player) => ({
          ...player,
          isNewChat:
            typeof player.isNewChat !== 'undefined' ? player.isNewChat : false
        }));

        return [
          ...result.filter((res) => res.isNewChat),
          ...result.filter((res) => !res.isNewChat)
        ];
      });
    },
    [setPlayers]
  );

  const showTeamIsNewChat = useCallback(
    (teamCode: string | number, isNewChat: boolean) => {
      setTeams((prev) => {
        const teamTemp: TeamResponse[] = cloneDeep(prev);
        let teamIdx = -1;

        if (teamCode === 0) {
          teamIdx = teamTemp.findIndex((team) => team.id === 0);
          teamTemp[teamIdx].isNewChat = isNewChat;
        } else {
          teamIdx = teamTemp.findIndex((team) => team.teamCode === teamCode);
          if (teamIdx > -1) {
            teamTemp[teamIdx].isNewChat = isNewChat;
          }
        }

        const result = teamTemp.map((team) => ({
          ...team,
          isNewChat:
            typeof team.isNewChat !== 'undefined' ? team.isNewChat : false
        }));

        return [
          ...result.filter((res) => res.isNewChat),
          ...result.filter((res) => !res.isNewChat)
        ];
      });
    },
    [setTeams]
  );

  const onClickPlayerHandler = useCallback(
    (player: PlayerResponse) => {
      setChatType('Player');
      setSelectedItem(player);
      showPlayerIsNewChat(player.code!, false);
      setNewChat((prev) =>
        prev.filter((chat) => chat.group !== `PlayerChat_${player.code}`)
      );
    },
    [showPlayerIsNewChat, setNewChat]
  );

  const onClickPlayerChatHitoryHandler = useCallback(
    (contact: PlayerContactResponse) => {
      setChatType('PlayerHistory');
      setSelectedItem(contact);
    },
    [setChatType, setSelectedItem]
  );

  const onClickHandler = useCallback(
    (item: TeamResponse) => {
      setChatType('');
      setSelectedItem(item);
      if (item.id === 0) {
        showTeamIsNewChat(0, false);
        setNewChat((prev) =>
          prev.filter((chat) => !chat.group?.includes('GlobalChat'))
        );
      } else {
        showTeamIsNewChat(item.teamCode as string, false);
        setNewChat((prev) =>
          prev.filter((chat) => chat.group !== `TeamChat_${item.teamCode}`)
        );
      }
    },
    [showTeamIsNewChat, setNewChat]
  );

  const onSendMessageHandler = useCallback(
    async (message: string) => {
      if (chatType === 'Player') {
        let payload: ChatFromFacilitator = {
          facilitatorName: userName,
          facilitatorUserProfileId: userId,
          toPlayerCode: (selectedItem as PlayerResponse).code!,
          message: message
        };

        await PostChatFromFacilitator(gameDocument?.gameCode!, payload);
        onClickPlayerHandler(selectedItem as PlayerResponse);
      } else {
        if (selectedItem?.name?.toLowerCase() === 'all teams') {
          await sendFacilitatorChat(
            gameDocument?.gameCode!,
            userId!,
            userName!,
            message!
          );
        } else if (selectedTeamItem?.teamCode) {
          await sendFacilitatorTeamChat(
            gameDocument?.gameCode!,
            selectedTeamItem?.teamCode,
            userId!,
            userName!,
            message!
          );
        } else {
          await sendFacilitatorGroupChat(
            gameDocument?.gameCode!,
            selectedGroupItem?.code,
            userId!,
            userName!,
            message!
          );
        }
        onClickHandler(selectedItem as TeamResponse);
      }
    },
    [
      chatType,
      userName,
      userId,
      selectedItem,
      gameDocument?.gameCode,
      selectedTeamItem?.teamCode,
      selectedGroupItem?.code,
      onClickPlayerHandler,
      onClickHandler
    ]
  );

  useEffect(() => {
    getPlayersChatHistory();
  }, [getPlayersChatHistory]);

  useEffect(() => {
    setSelectedItem({});
    setChatType('');
    if (gameDocument.gameCode) {
      getListOfTeam(gameDocument.gameCode);
      getAllPlayers(gameDocument.gameCode);
    }
  }, [gameDocument.gameCode, getAllPlayers, getListOfTeam, setSelectedItem]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div className="h-100 mh-100 w-100 row m-0 p-0" ref={containerRef}>
        <HelpSupport
          title="Chat guide"
          url="https://forum.catalystglobal.com/t/4650"
        />
        {containerRef.current && (
          <div
            className="col-3 "
            style={{
              height: containerRef?.current?.clientHeight,
              overflowY: 'auto'
            }}>
            <div>
              <FacilitatorList
                listData={teams}
                onClick={onClickHandler}
                dataItem={selectedItem as any}
              />
            </div>
            <div className={'mt-3'}>
              <FacilitatorList
                listData={players}
                onClick={onClickPlayerHandler}
                dataItem={selectedItem as any}
              />
            </div>
            <div className={'mt-3'}>
              <FacilitatorList
                listData={playersChatHistory}
                onClick={onClickPlayerChatHitoryHandler}
                dataItem={selectedItem as any}
                listType={'playerchat'}
              />
            </div>
          </div>
        )}
        <div className="col-9">
          {selectedTeamItem?.name?.toLowerCase() === 'all teams' && (
            <FacilitatorAllteamChat
              item={selectedItem as any}
              onSendMessage={onSendMessageHandler}
            />
          )}
          {selectedTeamItem?.teamCode && (
            <FacilitatorTeamChat
              code={(selectedItem as any)?.teamCode}
              item={selectedItem as any}
              onSendMessage={onSendMessageHandler}
            />
          )}
          {(selectedTeamItem as GroupResponse).code &&
            chatType !== 'Player' && (
              <FacilitatorGroupChat
                item={selectedItem as any}
                onSendMessage={onSendMessageHandler}
              />
            )}
          {(selectedTeamItem as PlayerResponse).code &&
            chatType === 'Player' && (
              <FacilitatorAndPlayerChat
                item={selectedItem as any}
                onSendMessage={onSendMessageHandler}
              />
            )}
          {(selectedTeamItem as PlayerContactResponse).playerCode1 &&
            chatType === 'PlayerHistory' && (
              <FacilitatorBetweenPlayerChat
                item={selectedItem as any}
                onSendMessage={onSendMessageHandler}
              />
            )}
        </div>
      </div>
    </>
  );
};
