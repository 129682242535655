import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Error } from '@progress/kendo-react-labels';
import {
  Checkbox,
  Input,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import { FeedbackDocumentContext } from '../../contexts/feedback-document';
import { UpdateFeedbackDocumentState } from '../../utils/feedback-document';
import cloneDeep from 'lodash.clonedeep';
import { uuid } from '../../types/common-helper';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import merge from 'lodash.merge';
import {
  FeedbackEntity,
  FeedbackLanguage
} from '../../types/feedback-document';
import {
  getFeedbackAsync,
  getFeedbackLanguagesAsync
} from '../../services/feedbacks';
import {
  generateTitleById,
  GetDisplayLanguageJsonAsync
} from '../../utils/game-document/display-languages';
import { DisplayLanguageFeedbackContext } from '../../contexts/display-languages-feedback';
import { Language } from '../../types/game-document/entities/language';

const defaultLanguage: Language = {
  id: '00000000-0000-0000-0000-000000000000',
  name: 'Default',
  displayLanguageUrl: '',
  displayLanguage: ''
};

export const FeedbackPlayerName = () => {
  const [state, setState] = useContext(FeedbackDocumentContext);
  const [feedbackDisplayLangContext, setFeedbackDisplayLangContext] =
    useContext(DisplayLanguageFeedbackContext);
  const { feedbackCode } = useParams();
  const navigate = useNavigate();
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [selectedName, setSelectedName] = useState(
    state.feedbackDocument?.playerName ?? ''
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [languages, setLanguages] = useState<FeedbackLanguage[]>([]);
  const [feedback, setFeedback] = useState<FeedbackEntity>();
  const [selectedLanguage, setSelectedLanguage] =
    useState<Language>(defaultLanguage);

  const [status, setStatus] = useState<string>('Completed');

  const handleNameCodeChange = (e: InputChangeEvent) => {
    setSelectedName(e.value);
    let newFeedbackDocument = cloneDeep(state.feedbackDocument);
    if (newFeedbackDocument?.playerName === undefined) {
      newFeedbackDocument!.playerName = '';
    }
    newFeedbackDocument!.playerName = e.value;
    //update feedback document
    setState(UpdateFeedbackDocumentState(state!, newFeedbackDocument!));

    if (!isValid) {
      setIsValid(true);
    }
  };

  const getLanguagesAsync = async () => {
    if (feedbackCode) {
      let response = await getFeedbackLanguagesAsync(feedbackCode!);
      if (response) {
        setLanguages(response);
      }
    }
  };

  const validateFeedbackAsync = async () => {
    if (feedbackCode) {
      let response = await getFeedbackAsync(feedbackCode!);
      if (response) {
        setFeedback(response);
        setStatus(response.status);
      }
    }
  };

  const handleOnChange = (event: DropDownListChangeEvent) => {
    const languageSelected = { ...event.target.value };
    setSelectedLanguage({ ...event.target.value });
    setState(() =>
      merge({
        ...state,
        isLoaded: true,
        selectedLanguageId: event.value?.id!
      })
    );
    let url =
      event.target.value.displayLanguageUrl ||
      state.feedbackDocument?.displayLanguageUrl;

    //set selected display language
    GetDisplayLanguageJsonAsync(url).then((response) => {
      setFeedbackDisplayLangContext((prev) => ({
        ...prev,
        displayLanguageSelected: response
      }));
    });
  };

  const handleNext = async () => {
    let codeIsValid = isAnonymous ? true : selectedName !== '';
    let newFeedbackDocument = cloneDeep(state.feedbackDocument);

    if (codeIsValid) {
      newFeedbackDocument!.playerCode = isAnonymous ? null : uuid();
      //update feedback document
      setState(UpdateFeedbackDocumentState(state!, newFeedbackDocument!));

      // check if the Feedback event have any related games
      if (state.eventGames?.length === 0) {
        // skip the select game page, directly navigate to the form
        navigate(`/feedbacks/${feedbackCode}/form`);
      } else {
        // navigate to the Feedback games page
        navigate(`/feedbacks/${feedbackCode}/games`);
      }
    }
    setIsValid(codeIsValid);
  };

  useEffect(() => {
    validateFeedbackAsync();
    if (feedback?.status === 'Running') {
      getLanguagesAsync();
    }
  }, []);

  useEffect(() => {
    if (
      state.feedbackDocument?.resourcePacks &&
      state.feedbackDocument.resourcePacks.length > 0
    ) {
      defaultLanguage.name = state.feedbackDocument?.language ?? 'Default';
      const defaultLanguageState: Language = {
        ...defaultLanguage,
        displayLanguage: state.feedbackDocument?.displayLanguage,
        displayLanguageUrl: state.feedbackDocument?.displayLanguageUrl
      };
      const availableLanguage: Language[] = [defaultLanguageState].concat(
        state.feedbackDocument?.resourcePacks?.map<Language>((item: any) => ({
          id: item.id,
          name: item.name,
          displayLanguageUrl: item.displayLanguageUrl,
          displayLanguage: item.displayLanguage
        }))
      );
      setState(() =>
        merge({
          ...state,
          isLoaded: true,
          selectedLanguageId: defaultLanguage.id
        })
      );
      setSelectedLanguage({ ...defaultLanguageState });
      setLanguages([...availableLanguage]);
    }
  }, [state.feedbackDocument?.displayLanguage]);

  return (
    <>
      {status === 'Running' && (
        <div className={'layout layout--pre-game'}>
          <div className={'layout__container'}>
            <Container className={'rounded shadow'}>
              <Row className={'align-items-center'}>
                <Col />
                <Col className={'text-center'}>
                  {state.feedbackDocument?.logoUrl ? (
                    <img
                      src={state.feedbackDocument?.logoUrl}
                      alt="img"
                      style={{ width: 64 }}
                    />
                  ) : (
                    <Logo style={{ width: 64 }} />
                  )}
                </Col>
                <Col />
              </Row>
              <Row className={'gy-4 mt-4'}>
                <Col xs={12}>
                  <label>
                    {generateTitleById(
                      '3ed6fe99-9545-44b2-9800-52495d998967',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Name'}
                  </label>
                  <Input
                    value={selectedName}
                    onChange={handleNameCodeChange}
                    placeholder={'Name'}
                    className={'w-100'}
                    valid={isValid}
                    aria-describedby={'name'}
                    disabled={isAnonymous}
                  />
                  {!isValid && (
                    <Error id={'feedback-name'}>Name is invalid.</Error>
                  )}
                </Col>
                <Col xs={12}>
                  <label>
                    {generateTitleById(
                      'd594b340-e6db-4822-9dfa-6701afbd5677',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Language'}
                  </label>
                  <DropDownList
                    data={languages}
                    textField={'name'}
                    value={selectedLanguage}
                    onChange={handleOnChange}
                  />
                </Col>
                <Col>
                  <Checkbox
                    id={'anonymous-name-feedback'}
                    className={'mr-2'}
                    name={'anonymous'}
                    value={isAnonymous}
                    label={
                      generateTitleById(
                        '8f2695c9-3074-48bf-b68a-76d5beaffa44',
                        state,
                        feedbackDisplayLangContext.displayLanguageSelected
                          .resources!,
                        'feedback'
                      ) || 'Anonymous'
                    }
                    onChange={() => setIsAnonymous(!isAnonymous)}
                  />
                </Col>
                <Col xs={12}>
                  <Button
                    className={'k-button--gradient w-100'}
                    themeColor={'primary'}
                    onClick={handleNext}>
                    {generateTitleById(
                      '6101dd6b-0e30-4518-b9d9-37a4173c1031',
                      state,
                      feedbackDisplayLangContext.displayLanguageSelected
                        .resources!,
                      'feedback'
                    ) || 'Next'}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}

      {status !== 'Running' && (
        <div className={'mask'}>
          <Container>
            <Row className={'mt-4'}>
              <Col className={'text-center'}>
                {state.feedbackDocument?.logoUrl ? (
                  <img
                    src={state.feedbackDocument?.logoUrl}
                    alt={''}
                    style={{ maxWidth: '10rem' }}
                    className={'rounded'}
                  />
                ) : (
                  <Logo style={{ width: 64 }} />
                )}
              </Col>
            </Row>
            <Row className={'mt-4'}>
              <Col className={'text-center'}>
                {status === 'NotStarted' && (
                  <span>
                    Thank you for your interest in contributing your valuable
                    feedback! Please note that the survey period has not started
                    yet.
                  </span>
                )}
                {status === 'Completed' && (
                  <span>
                    Thank you for your interest in contributing your valuable
                    feedback! Please note that the survey period has now ended.
                  </span>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};
